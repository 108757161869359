body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.front-container1 {
  background-color: #edf1f6;
  color: #75868f;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
  grid-template-rows: min-content 1fr min-content;
}


.justify-content-center {
  justify-content: center !important;
}

.text-center {
  text-align: center !important;
}

.text-cyan-400 {
  --bs-text-opacity: 1;
  color: rgba(61, 213, 243, var(--bs-text-opacity)) !important;
}
.text-teal-400 {
  --bs-text-opacity: 1;
  color: rgba(81, 184, 174, var(--bs-text-opacity)) !important;
}
.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(237, 241, 246, var(--bs-text-opacity)) !important;
}

.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(203, 208, 216, var(--bs-text-opacity)) !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}



.error-code-lg {
  font-size: 9rem !important;
  font-weight: 400;
  line-height: 1;
}

.error-code-md {
  font-size: 6rem !important;
  font-weight: 400;
  line-height: 1;
}

.error-code-sm {
  font-size: 3rem !important;
  font-weight: 400;
  line-height: 1;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
.centered-layout .content__boxed, .centered-layout .header__inner, .centered-layout .footer {
  margin: 0 auto;
  min-width: calc( 990px + 2.5rem);
  max-width: calc( 990px + 2.5rem);
}

.w-100 {
  width: 100% !important; 
}

.min-vh-100 {
  min-height: 100vh !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}


.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.PageDivider{
  min-height: 100%;
  width: .1rem;
  margin: 0 3.5rem;
  background-color: #000000;
}

.signup-header {
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 6rem;
}
@font-face {
    font-family: "premium-solid-icons";
    src: url(/static/media/premium-solid-icons.61a95c35.woff2) format("woff2");
    font-weight: normal;
    font-style: normal;
}

[class^="psi-"], [class*=" psi-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "premium-solid-icons" !important;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;

    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    vertical-align: middle;
}


.psi-3d-glasses:before {
    content: "\E900";
}

.psi-3d-glasses-2:before {
    content: "\E901";
}

.psi-a-z:before {
    content: "\E902";
}

.psi-aa:before {
    content: "\E903";
}

.psi-add:before {
    content: "\E904";
}

.psi-add-bag:before {
    content: "\E905";
}

.psi-add-basket:before {
    content: "\E906";
}

.psi-add-cart:before {
    content: "\E907";
}

.psi-add-space-after-paragraph:before {
    content: "\E908";
}

.psi-add-space-before-paragraph:before {
    content: "\E909";
}

.psi-add-user:before {
    content: "\E90A";
}

.psi-add-user-star:before {
    content: "\E90B";
}

.psi-add-window:before {
    content: "\E90C";
}

.psi-address-book:before {
    content: "\E90D";
}

.psi-address-book-2:before {
    content: "\E90E";
}

.psi-administrator:before {
    content: "\E90F";
}

.psi-aerobics:before {
    content: "\E910";
}

.psi-aerobics-2:before {
    content: "\E911";
}

.psi-aerobics-3:before {
    content: "\E912";
}

.psi-affiliate:before {
    content: "\E913";
}

.psi-aim:before {
    content: "\E914";
}

.psi-air-balloon:before {
    content: "\E915";
}

.psi-airbrush:before {
    content: "\E916";
}

.psi-airship:before {
    content: "\E917";
}

.psi-alarm:before {
    content: "\E918";
}

.psi-alarm-clock:before {
    content: "\E919";
}

.psi-alarm-clock-2:before {
    content: "\E91A";
}

.psi-alien:before {
    content: "\E91B";
}

.psi-alien-2:before {
    content: "\E91C";
}

.psi-align-center:before {
    content: "\E91D";
}

.psi-align-justify-all:before {
    content: "\E91E";
}

.psi-align-justify-center:before {
    content: "\E91F";
}

.psi-align-justify-left:before {
    content: "\E920";
}

.psi-align-justify-right:before {
    content: "\E921";
}

.psi-align-left:before {
    content: "\E922";
}

.psi-align-right:before {
    content: "\E923";
}

.psi-alligator:before {
    content: "\E924";
}

.psi-alpha:before {
    content: "\E925";
}

.psi-ambulance:before {
    content: "\E926";
}

.psi-amx:before {
    content: "\E927";
}

.psi-anchor:before {
    content: "\E928";
}

.psi-anchor-2:before {
    content: "\E929";
}

.psi-android:before {
    content: "\E92A";
}

.psi-android-store:before {
    content: "\E92B";
}

.psi-angel:before {
    content: "\E92C";
}

.psi-angel-smiley:before {
    content: "\E92D";
}

.psi-angry:before {
    content: "\E92E";
}

.psi-apple:before {
    content: "\E92F";
}

.psi-apple-bite:before {
    content: "\E930";
}

.psi-apple-store:before {
    content: "\E931";
}

.psi-approved-window:before {
    content: "\E932";
}

.psi-aquarius:before {
    content: "\E933";
}

.psi-aquarius-2:before {
    content: "\E934";
}

.psi-archery:before {
    content: "\E935";
}

.psi-archery-2:before {
    content: "\E936";
}

.psi-argentina:before {
    content: "\E937";
}

.psi-aries:before {
    content: "\E938";
}

.psi-aries-2:before {
    content: "\E939";
}

.psi-army-key:before {
    content: "\E93A";
}

.psi-arrow-around:before {
    content: "\E93B";
}

.psi-arrow-back:before {
    content: "\E93C";
}

.psi-arrow-back-2:before {
    content: "\E93D";
}

.psi-arrow-back-3:before {
    content: "\E93E";
}

.psi-arrow-barrier:before {
    content: "\E93F";
}

.psi-arrow-circle:before {
    content: "\E940";
}

.psi-arrow-cross:before {
    content: "\E941";
}

.psi-arrow-down:before {
    content: "\E942";
}

.psi-arrow-down-2:before {
    content: "\E943";
}

.psi-arrow-down-3:before {
    content: "\E944";
}

.psi-arrow-down-in-circle:before {
    content: "\E945";
}

.psi-arrow-fork:before {
    content: "\E946";
}

.psi-arrow-forward:before {
    content: "\E947";
}

.psi-arrow-forward-2:before {
    content: "\E948";
}

.psi-arrow-forward-3:before {
    content: "\E949";
}

.psi-arrow-from:before {
    content: "\E94A";
}

.psi-arrow-inside:before {
    content: "\E94B";
}

.psi-arrow-inside-45:before {
    content: "\E94C";
}

.psi-arrow-inside-gap:before {
    content: "\E94D";
}

.psi-arrow-inside-gap-45:before {
    content: "\E94E";
}

.psi-arrow-into:before {
    content: "\E94F";
}

.psi-arrow-join:before {
    content: "\E950";
}

.psi-arrow-junction:before {
    content: "\E951";
}

.psi-arrow-left:before {
    content: "\E952";
}

.psi-arrow-left-2:before {
    content: "\E953";
}

.psi-arrow-left-in-circle:before {
    content: "\E954";
}

.psi-arrow-loop:before {
    content: "\E955";
}

.psi-arrow-merge:before {
    content: "\E956";
}

.psi-arrow-mix:before {
    content: "\E957";
}

.psi-arrow-out-left:before {
    content: "\E958";
}

.psi-arrow-out-right:before {
    content: "\E959";
}

.psi-arrow-outside:before {
    content: "\E95A";
}

.psi-arrow-outside-45:before {
    content: "\E95B";
}

.psi-arrow-outside-gap:before {
    content: "\E95C";
}

.psi-arrow-outside-gap-45:before {
    content: "\E95D";
}

.psi-arrow-over:before {
    content: "\E95E";
}

.psi-arrow-refresh:before {
    content: "\E95F";
}

.psi-arrow-refresh-2:before {
    content: "\E960";
}

.psi-arrow-right:before {
    content: "\E961";
}

.psi-arrow-right-2:before {
    content: "\E962";
}

.psi-arrow-right-in-circle:before {
    content: "\E963";
}

.psi-arrow-shuffle:before {
    content: "\E964";
}

.psi-arrow-squiggly:before {
    content: "\E965";
}

.psi-arrow-through:before {
    content: "\E966";
}

.psi-arrow-to:before {
    content: "\E967";
}

.psi-arrow-turn-left:before {
    content: "\E968";
}

.psi-arrow-turn-right:before {
    content: "\E969";
}

.psi-arrow-up:before {
    content: "\E96A";
}

.psi-arrow-up-2:before {
    content: "\E96B";
}

.psi-arrow-up-3:before {
    content: "\E96C";
}

.psi-arrow-up-in-circle:before {
    content: "\E96D";
}

.psi-arrow-x-left:before {
    content: "\E96E";
}

.psi-arrow-x-right:before {
    content: "\E96F";
}

.psi-ask:before {
    content: "\E970";
}

.psi-assistant:before {
    content: "\E971";
}

.psi-astronaut:before {
    content: "\E972";
}

.psi-at-sign:before {
    content: "\E973";
}

.psi-atm:before {
    content: "\E974";
}

.psi-atom:before {
    content: "\E975";
}

.psi-audio:before {
    content: "\E976";
}

.psi-auto-flash:before {
    content: "\E977";
}

.psi-autumn:before {
    content: "\E978";
}

.psi-baby:before {
    content: "\E979";
}

.psi-baby-clothes:before {
    content: "\E97A";
}

.psi-baby-clothes-2:before {
    content: "\E97B";
}

.psi-baby-cry:before {
    content: "\E97C";
}

.psi-back:before {
    content: "\E97D";
}

.psi-back-media:before {
    content: "\E97E";
}

.psi-back-media-2:before {
    content: "\E97F";
}

.psi-back-music:before {
    content: "\E980";
}

.psi-background:before {
    content: "\E981";
}

.psi-bacteria:before {
    content: "\E982";
}

.psi-bag:before {
    content: "\E983";
}

.psi-bag-coin:before {
    content: "\E984";
}

.psi-bag-items:before {
    content: "\E985";
}

.psi-bag-quantity:before {
    content: "\E986";
}

.psi-bakelite:before {
    content: "\E987";
}

.psi-ballet-shoes:before {
    content: "\E988";
}

.psi-balloon:before {
    content: "\E989";
}

.psi-banana:before {
    content: "\E98A";
}

.psi-band-aid:before {
    content: "\E98B";
}

.psi-bank:before {
    content: "\E98C";
}

.psi-bar-chart:before {
    content: "\E98D";
}

.psi-bar-chart-2:before {
    content: "\E98E";
}

.psi-bar-chart-3:before {
    content: "\E98F";
}

.psi-bar-chart-4:before {
    content: "\E990";
}

.psi-bar-chart-5:before {
    content: "\E991";
}

.psi-bar-code:before {
    content: "\E992";
}

.psi-barricade:before {
    content: "\E993";
}

.psi-barricade-2:before {
    content: "\E994";
}

.psi-baseball:before {
    content: "\E995";
}

.psi-basket-ball:before {
    content: "\E996";
}

.psi-basket-coins:before {
    content: "\E997";
}

.psi-basket-items:before {
    content: "\E998";
}

.psi-basket-quantity:before {
    content: "\E999";
}

.psi-bat:before {
    content: "\E99A";
}

.psi-bat-2:before {
    content: "\E99B";
}

.psi-bathrobe:before {
    content: "\E99C";
}

.psi-batman-mask:before {
    content: "\E99D";
}

.psi-battery-0:before {
    content: "\E99E";
}

.psi-battery-100:before {
    content: "\E99F";
}

.psi-battery-25:before {
    content: "\E9A0";
}

.psi-battery-50:before {
    content: "\E9A1";
}

.psi-battery-75:before {
    content: "\E9A2";
}

.psi-battery-charge:before {
    content: "\E9A3";
}

.psi-bear:before {
    content: "\E9A4";
}

.psi-beard:before {
    content: "\E9A5";
}

.psi-beard-2:before {
    content: "\E9A6";
}

.psi-beard-3:before {
    content: "\E9A7";
}

.psi-bebo:before {
    content: "\E9A8";
}

.psi-bee:before {
    content: "\E9A9";
}

.psi-beer:before {
    content: "\E9AA";
}

.psi-beer-glass:before {
    content: "\E9AB";
}

.psi-behance:before {
    content: "\E9AC";
}

.psi-bell:before {
    content: "\E9AD";
}

.psi-bell-2:before {
    content: "\E9AE";
}

.psi-belt:before {
    content: "\E9AF";
}

.psi-belt-2:before {
    content: "\E9B0";
}

.psi-belt-3:before {
    content: "\E9B1";
}

.psi-berlin-tower:before {
    content: "\E9B2";
}

.psi-beta:before {
    content: "\E9B3";
}

.psi-betvibes:before {
    content: "\E9B4";
}

.psi-bicycle:before {
    content: "\E9B5";
}

.psi-bicycle-2:before {
    content: "\E9B6";
}

.psi-bicycle-3:before {
    content: "\E9B7";
}

.psi-big-ben:before {
    content: "\E9B8";
}

.psi-big-data:before {
    content: "\E9B9";
}

.psi-bike-helmet:before {
    content: "\E9BA";
}

.psi-bikini:before {
    content: "\E9BB";
}

.psi-billing:before {
    content: "\E9BC";
}

.psi-bing:before {
    content: "\E9BD";
}

.psi-binocular:before {
    content: "\E9BE";
}

.psi-bio-hazard:before {
    content: "\E9BF";
}

.psi-biotech:before {
    content: "\E9C0";
}

.psi-bird:before {
    content: "\E9C1";
}

.psi-bird-delivering-letter:before {
    content: "\E9C2";
}

.psi-birthday-cake:before {
    content: "\E9C3";
}

.psi-bisexual:before {
    content: "\E9C4";
}

.psi-bishop:before {
    content: "\E9C5";
}

.psi-bitcoin:before {
    content: "\E9C6";
}

.psi-black-cat:before {
    content: "\E9C7";
}

.psi-blackboard:before {
    content: "\E9C8";
}

.psi-blinklist:before {
    content: "\E9C9";
}

.psi-block-cloud:before {
    content: "\E9CA";
}

.psi-block-window:before {
    content: "\E9CB";
}

.psi-blogger:before {
    content: "\E9CC";
}

.psi-blood:before {
    content: "\E9CD";
}

.psi-blouse:before {
    content: "\E9CE";
}

.psi-blueprint:before {
    content: "\E9CF";
}

.psi-board:before {
    content: "\E9D0";
}

.psi-bodybuilding:before {
    content: "\E9D1";
}

.psi-bold-text:before {
    content: "\E9D2";
}

.psi-bone:before {
    content: "\E9D3";
}

.psi-bones:before {
    content: "\E9D4";
}

.psi-book:before {
    content: "\E9D5";
}

.psi-bookmark:before {
    content: "\E9D6";
}

.psi-books:before {
    content: "\E9D7";
}

.psi-books-2:before {
    content: "\E9D8";
}

.psi-boom:before {
    content: "\E9D9";
}

.psi-boot:before {
    content: "\E9DA";
}

.psi-boot-2:before {
    content: "\E9DB";
}

.psi-bottom-to-top:before {
    content: "\E9DC";
}

.psi-bow:before {
    content: "\E9DD";
}

.psi-bow-2:before {
    content: "\E9DE";
}

.psi-bow-3:before {
    content: "\E9DF";
}

.psi-bow-4:before {
    content: "\E9E0";
}

.psi-bow-5:before {
    content: "\E9E1";
}

.psi-bow-6:before {
    content: "\E9E2";
}

.psi-bowling:before {
    content: "\E9E3";
}

.psi-bowling-2:before {
    content: "\E9E4";
}

.psi-box:before {
    content: "\E9E5";
}

.psi-box-close:before {
    content: "\E9E6";
}

.psi-box-full:before {
    content: "\E9E7";
}

.psi-box-gloves:before {
    content: "\E9E8";
}

.psi-box-open:before {
    content: "\E9E9";
}

.psi-box-with-folders:before {
    content: "\E9EA";
}

.psi-boy:before {
    content: "\E9EB";
}

.psi-bra:before {
    content: "\E9EC";
}

.psi-brain:before {
    content: "\E9ED";
}

.psi-brain-2:before {
    content: "\E9EE";
}

.psi-brain-3:before {
    content: "\E9EF";
}

.psi-brazil:before {
    content: "\E9F0";
}

.psi-bread:before {
    content: "\E9F1";
}

.psi-bread-2:before {
    content: "\E9F2";
}

.psi-bridge:before {
    content: "\E9F3";
}

.psi-brightkite:before {
    content: "\E9F4";
}

.psi-broken-link:before {
    content: "\E9F5";
}

.psi-broken-link-2:before {
    content: "\E9F6";
}

.psi-broom:before {
    content: "\E9F7";
}

.psi-brush:before {
    content: "\E9F8";
}

.psi-bucket:before {
    content: "\E9F9";
}

.psi-bug:before {
    content: "\E9FA";
}

.psi-building:before {
    content: "\E9FB";
}

.psi-bulleted-list:before {
    content: "\E9FC";
}

.psi-bus:before {
    content: "\E9FD";
}

.psi-bus-2:before {
    content: "\E9FE";
}

.psi-business-man:before {
    content: "\E9FF";
}

.psi-business-man-woman:before {
    content: "\EA00";
}

.psi-business-mens:before {
    content: "\EA01";
}

.psi-business-woman:before {
    content: "\EA02";
}

.psi-butterfly:before {
    content: "\EA03";
}

.psi-button:before {
    content: "\EA04";
}

.psi-cable-car:before {
    content: "\EA05";
}

.psi-cake:before {
    content: "\EA06";
}

.psi-calculator:before {
    content: "\EA07";
}

.psi-calculator-2:before {
    content: "\EA08";
}

.psi-calculator-3:before {
    content: "\EA09";
}

.psi-calendar:before {
    content: "\EA0A";
}

.psi-calendar-2:before {
    content: "\EA0B";
}

.psi-calendar-3:before {
    content: "\EA0C";
}

.psi-calendar-4:before {
    content: "\EA0D";
}

.psi-calendar-clock:before {
    content: "\EA0E";
}

.psi-camel:before {
    content: "\EA0F";
}

.psi-camera:before {
    content: "\EA10";
}

.psi-camera-2:before {
    content: "\EA11";
}

.psi-camera-3:before {
    content: "\EA12";
}

.psi-camera-4:before {
    content: "\EA13";
}

.psi-camera-5:before {
    content: "\EA14";
}

.psi-camera-back:before {
    content: "\EA15";
}

.psi-can:before {
    content: "\EA16";
}

.psi-can-2:before {
    content: "\EA17";
}

.psi-canada:before {
    content: "\EA18";
}

.psi-cancer:before {
    content: "\EA19";
}

.psi-cancer-2:before {
    content: "\EA1A";
}

.psi-cancer-3:before {
    content: "\EA1B";
}

.psi-candle:before {
    content: "\EA1C";
}

.psi-candy:before {
    content: "\EA1D";
}

.psi-candy-cane:before {
    content: "\EA1E";
}

.psi-cannon:before {
    content: "\EA1F";
}

.psi-cap:before {
    content: "\EA20";
}

.psi-cap-2:before {
    content: "\EA21";
}

.psi-cap-3:before {
    content: "\EA22";
}

.psi-cap-smiley:before {
    content: "\EA23";
}

.psi-capricorn:before {
    content: "\EA24";
}

.psi-capricorn-2:before {
    content: "\EA25";
}

.psi-car:before {
    content: "\EA26";
}

.psi-car-2:before {
    content: "\EA27";
}

.psi-car-3:before {
    content: "\EA28";
}

.psi-car-wheel:before {
    content: "\EA29";
}

.psi-cardigan:before {
    content: "\EA2A";
}

.psi-cardiovascular:before {
    content: "\EA2B";
}

.psi-cart-coin:before {
    content: "\EA2C";
}

.psi-cart-items:before {
    content: "\EA2D";
}

.psi-cart-quantity:before {
    content: "\EA2E";
}

.psi-casette-tape:before {
    content: "\EA2F";
}

.psi-cash-register:before {
    content: "\EA30";
}

.psi-cash-register-2:before {
    content: "\EA31";
}

.psi-castle:before {
    content: "\EA32";
}

.psi-cat:before {
    content: "\EA33";
}

.psi-cathedral:before {
    content: "\EA34";
}

.psi-cauldron:before {
    content: "\EA35";
}

.psi-cd:before {
    content: "\EA36";
}

.psi-cd-2:before {
    content: "\EA37";
}

.psi-cd-cover:before {
    content: "\EA38";
}

.psi-cello:before {
    content: "\EA39";
}

.psi-celsius:before {
    content: "\EA3A";
}

.psi-chair:before {
    content: "\EA3B";
}

.psi-charger:before {
    content: "\EA3C";
}

.psi-check:before {
    content: "\EA3D";
}

.psi-check-2:before {
    content: "\EA3E";
}

.psi-checked-user:before {
    content: "\EA3F";
}

.psi-checkered-flag:before {
    content: "\EA40";
}

.psi-checkmate:before {
    content: "\EA41";
}

.psi-checkout:before {
    content: "\EA42";
}

.psi-checkout-bag:before {
    content: "\EA43";
}

.psi-checkout-basket:before {
    content: "\EA44";
}

.psi-cheese:before {
    content: "\EA45";
}

.psi-cheetah:before {
    content: "\EA46";
}

.psi-chef:before {
    content: "\EA47";
}

.psi-chef-hat:before {
    content: "\EA48";
}

.psi-chef-hat-2:before {
    content: "\EA49";
}

.psi-chemical:before {
    content: "\EA4A";
}

.psi-chemical-2:before {
    content: "\EA4B";
}

.psi-chemical-3:before {
    content: "\EA4C";
}

.psi-chemical-4:before {
    content: "\EA4D";
}

.psi-chemical-5:before {
    content: "\EA4E";
}

.psi-chess:before {
    content: "\EA4F";
}

.psi-chess-board:before {
    content: "\EA50";
}

.psi-chevron-down:before {
    content: "\EA51";
}

.psi-chevron-left:before {
    content: "\EA52";
}

.psi-chevron-right:before {
    content: "\EA53";
}

.psi-chevron-up:before {
    content: "\EA54";
}

.psi-chicken:before {
    content: "\EA55";
}

.psi-chile:before {
    content: "\EA56";
}

.psi-chimney:before {
    content: "\EA57";
}

.psi-china:before {
    content: "\EA58";
}

.psi-chinese-temple:before {
    content: "\EA59";
}

.psi-chip:before {
    content: "\EA5A";
}

.psi-chopsticks:before {
    content: "\EA5B";
}

.psi-chopsticks-2:before {
    content: "\EA5C";
}

.psi-christmas:before {
    content: "\EA5D";
}

.psi-christmas-ball:before {
    content: "\EA5E";
}

.psi-christmas-bell:before {
    content: "\EA5F";
}

.psi-christmas-candle:before {
    content: "\EA60";
}

.psi-christmas-hat:before {
    content: "\EA61";
}

.psi-christmas-sleigh:before {
    content: "\EA62";
}

.psi-christmas-snowman:before {
    content: "\EA63";
}

.psi-christmas-sock:before {
    content: "\EA64";
}

.psi-christmas-tree:before {
    content: "\EA65";
}

.psi-chrome:before {
    content: "\EA66";
}

.psi-chrysler-building:before {
    content: "\EA67";
}

.psi-cinema:before {
    content: "\EA68";
}

.psi-circular-point:before {
    content: "\EA69";
}

.psi-city-hall:before {
    content: "\EA6A";
}

.psi-clamp:before {
    content: "\EA6B";
}

.psi-clapperboard-close:before {
    content: "\EA6C";
}

.psi-clapperboard-open:before {
    content: "\EA6D";
}

.psi-claps:before {
    content: "\EA6E";
}

.psi-clef:before {
    content: "\EA6F";
}

.psi-clinic:before {
    content: "\EA70";
}

.psi-clock:before {
    content: "\EA71";
}

.psi-clock-2:before {
    content: "\EA72";
}

.psi-clock-3:before {
    content: "\EA73";
}

.psi-clock-4:before {
    content: "\EA74";
}

.psi-clock-back:before {
    content: "\EA75";
}

.psi-clock-forward:before {
    content: "\EA76";
}

.psi-close:before {
    content: "\EA77";
}

.psi-close-window:before {
    content: "\EA78";
}

.psi-clothing-store:before {
    content: "\EA79";
}

.psi-cloud:before {
    content: "\EA7A";
}

.psi-cloud-camera:before {
    content: "\EA7B";
}

.psi-cloud-computer:before {
    content: "\EA7C";
}

.psi-cloud-email:before {
    content: "\EA7D";
}

.psi-cloud-hail:before {
    content: "\EA7E";
}

.psi-cloud-laptop:before {
    content: "\EA7F";
}

.psi-cloud-lock:before {
    content: "\EA80";
}

.psi-cloud-min:before {
    content: "\EA81";
}

.psi-cloud-moon:before {
    content: "\EA82";
}

.psi-cloud-music:before {
    content: "\EA83";
}

.psi-cloud-picture:before {
    content: "\EA84";
}

.psi-cloud-plus:before {
    content: "\EA85";
}

.psi-cloud-rain:before {
    content: "\EA86";
}

.psi-cloud-remove:before {
    content: "\EA87";
}

.psi-cloud-secure:before {
    content: "\EA88";
}

.psi-cloud-settings:before {
    content: "\EA89";
}

.psi-cloud-smartphone:before {
    content: "\EA8A";
}

.psi-cloud-snow:before {
    content: "\EA8B";
}

.psi-cloud-sun:before {
    content: "\EA8C";
}

.psi-cloud-tablet:before {
    content: "\EA8D";
}

.psi-cloud-video:before {
    content: "\EA8E";
}

.psi-cloud-weather:before {
    content: "\EA8F";
}

.psi-clouds:before {
    content: "\EA90";
}

.psi-clouds-weather:before {
    content: "\EA91";
}

.psi-clown:before {
    content: "\EA92";
}

.psi-cmyk:before {
    content: "\EA93";
}

.psi-coat:before {
    content: "\EA94";
}

.psi-cocktail:before {
    content: "\EA95";
}

.psi-coconut:before {
    content: "\EA96";
}

.psi-code-window:before {
    content: "\EA97";
}

.psi-coding:before {
    content: "\EA98";
}

.psi-coffee:before {
    content: "\EA99";
}

.psi-coffee-2:before {
    content: "\EA9A";
}

.psi-coffee-bean:before {
    content: "\EA9B";
}

.psi-coffee-machine:before {
    content: "\EA9C";
}

.psi-coffee-to-go:before {
    content: "\EA9D";
}

.psi-coffin:before {
    content: "\EA9E";
}

.psi-coin:before {
    content: "\EA9F";
}

.psi-coins:before {
    content: "\EAA0";
}

.psi-coins-2:before {
    content: "\EAA1";
}

.psi-coins-3:before {
    content: "\EAA2";
}

.psi-colombia:before {
    content: "\EAA3";
}

.psi-colosseum:before {
    content: "\EAA4";
}

.psi-column:before {
    content: "\EAA5";
}

.psi-column-2:before {
    content: "\EAA6";
}

.psi-column-3:before {
    content: "\EAA7";
}

.psi-comb:before {
    content: "\EAA8";
}

.psi-comb-2:before {
    content: "\EAA9";
}

.psi-communication-tower:before {
    content: "\EAAA";
}

.psi-communication-tower-2:before {
    content: "\EAAB";
}

.psi-compass:before {
    content: "\EAAC";
}

.psi-compass-2:before {
    content: "\EAAD";
}

.psi-compass-3:before {
    content: "\EAAE";
}

.psi-compass-4:before {
    content: "\EAAF";
}

.psi-compass-rose:before {
    content: "\EAB0";
}

.psi-computer:before {
    content: "\EAB1";
}

.psi-computer-2:before {
    content: "\EAB2";
}

.psi-computer-3:before {
    content: "\EAB3";
}

.psi-computer-secure:before {
    content: "\EAB4";
}

.psi-conference:before {
    content: "\EAB5";
}

.psi-confused:before {
    content: "\EAB6";
}

.psi-conservation:before {
    content: "\EAB7";
}

.psi-consulting:before {
    content: "\EAB8";
}

.psi-contrast:before {
    content: "\EAB9";
}

.psi-control:before {
    content: "\EABA";
}

.psi-control-2:before {
    content: "\EABB";
}

.psi-cookie-man:before {
    content: "\EABC";
}

.psi-cookies:before {
    content: "\EABD";
}

.psi-cool:before {
    content: "\EABE";
}

.psi-cool-guy:before {
    content: "\EABF";
}

.psi-copyright:before {
    content: "\EAC0";
}

.psi-costume:before {
    content: "\EAC1";
}

.psi-couple-sign:before {
    content: "\EAC2";
}

.psi-cow:before {
    content: "\EAC3";
}

.psi-cpu:before {
    content: "\EAC4";
}

.psi-crane:before {
    content: "\EAC5";
}

.psi-cranium:before {
    content: "\EAC6";
}

.psi-credit-card:before {
    content: "\EAC7";
}

.psi-credit-card-2:before {
    content: "\EAC8";
}

.psi-credit-card-3:before {
    content: "\EAC9";
}

.psi-cricket:before {
    content: "\EACA";
}

.psi-criminal:before {
    content: "\EACB";
}

.psi-croissant:before {
    content: "\EACC";
}

.psi-crop:before {
    content: "\EACD";
}

.psi-crop-2:before {
    content: "\EACE";
}

.psi-cross:before {
    content: "\EACF";
}

.psi-crown:before {
    content: "\EAD0";
}

.psi-crown-2:before {
    content: "\EAD1";
}

.psi-crying:before {
    content: "\EAD2";
}

.psi-cube-molecule:before {
    content: "\EAD3";
}

.psi-cube-molecule-2:before {
    content: "\EAD4";
}

.psi-cupcake:before {
    content: "\EAD5";
}

.psi-cursor:before {
    content: "\EAD6";
}

.psi-cursor-click:before {
    content: "\EAD7";
}

.psi-cursor-click-2:before {
    content: "\EAD8";
}

.psi-cursor-move:before {
    content: "\EAD9";
}

.psi-cursor-move-2:before {
    content: "\EADA";
}

.psi-cursor-select:before {
    content: "\EADB";
}

.psi-dam:before {
    content: "\EADC";
}

.psi-danger:before {
    content: "\EADD";
}

.psi-danger-2:before {
    content: "\EADE";
}

.psi-dashboard:before {
    content: "\EADF";
}

.psi-data:before {
    content: "\EAE0";
}

.psi-data-backup:before {
    content: "\EAE1";
}

.psi-data-block:before {
    content: "\EAE2";
}

.psi-data-center:before {
    content: "\EAE3";
}

.psi-data-clock:before {
    content: "\EAE4";
}

.psi-data-cloud:before {
    content: "\EAE5";
}

.psi-data-compress:before {
    content: "\EAE6";
}

.psi-data-copy:before {
    content: "\EAE7";
}

.psi-data-download:before {
    content: "\EAE8";
}

.psi-data-financial:before {
    content: "\EAE9";
}

.psi-data-key:before {
    content: "\EAEA";
}

.psi-data-lock:before {
    content: "\EAEB";
}

.psi-data-network:before {
    content: "\EAEC";
}

.psi-data-password:before {
    content: "\EAED";
}

.psi-data-power:before {
    content: "\EAEE";
}

.psi-data-refresh:before {
    content: "\EAEF";
}

.psi-data-save:before {
    content: "\EAF0";
}

.psi-data-search:before {
    content: "\EAF1";
}

.psi-data-security:before {
    content: "\EAF2";
}

.psi-data-settings:before {
    content: "\EAF3";
}

.psi-data-sharing:before {
    content: "\EAF4";
}

.psi-data-shield:before {
    content: "\EAF5";
}

.psi-data-signal:before {
    content: "\EAF6";
}

.psi-data-storage:before {
    content: "\EAF7";
}

.psi-data-stream:before {
    content: "\EAF8";
}

.psi-data-transfer:before {
    content: "\EAF9";
}

.psi-data-unlock:before {
    content: "\EAFA";
}

.psi-data-upload:before {
    content: "\EAFB";
}

.psi-data-yes:before {
    content: "\EAFC";
}

.psi-daylight:before {
    content: "\EAFD";
}

.psi-death:before {
    content: "\EAFE";
}

.psi-debian:before {
    content: "\EAFF";
}

.psi-dec:before {
    content: "\EB00";
}

.psi-decrease-indent:before {
    content: "\EB01";
}

.psi-deer:before {
    content: "\EB02";
}

.psi-deer-2:before {
    content: "\EB03";
}

.psi-delete-window:before {
    content: "\EB04";
}

.psi-delicious:before {
    content: "\EB05";
}

.psi-denmark:before {
    content: "\EB06";
}

.psi-depression:before {
    content: "\EB07";
}

.psi-deviantart:before {
    content: "\EB08";
}

.psi-device-sync-with-cloud:before {
    content: "\EB09";
}

.psi-diamond:before {
    content: "\EB0A";
}

.psi-dice:before {
    content: "\EB0B";
}

.psi-dice-2:before {
    content: "\EB0C";
}

.psi-digg:before {
    content: "\EB0D";
}

.psi-digital-drawing:before {
    content: "\EB0E";
}

.psi-diigo:before {
    content: "\EB0F";
}

.psi-dinosaur:before {
    content: "\EB10";
}

.psi-diploma:before {
    content: "\EB11";
}

.psi-diploma-2:before {
    content: "\EB12";
}

.psi-direction-east:before {
    content: "\EB13";
}

.psi-direction-north:before {
    content: "\EB14";
}

.psi-direction-south:before {
    content: "\EB15";
}

.psi-direction-west:before {
    content: "\EB16";
}

.psi-director:before {
    content: "\EB17";
}

.psi-disk:before {
    content: "\EB18";
}

.psi-dj:before {
    content: "\EB19";
}

.psi-dna:before {
    content: "\EB1A";
}

.psi-dna-2:before {
    content: "\EB1B";
}

.psi-dna-helix:before {
    content: "\EB1C";
}

.psi-doctor:before {
    content: "\EB1D";
}

.psi-dog:before {
    content: "\EB1E";
}

.psi-dollar:before {
    content: "\EB1F";
}

.psi-dollar-sign:before {
    content: "\EB20";
}

.psi-dollar-sign-2:before {
    content: "\EB21";
}

.psi-dolphin:before {
    content: "\EB22";
}

.psi-domino:before {
    content: "\EB23";
}

.psi-door:before {
    content: "\EB24";
}

.psi-door-hanger:before {
    content: "\EB25";
}

.psi-dopplr:before {
    content: "\EB26";
}

.psi-dot-horizontal:before {
    content: "\EB27";
}

.psi-dot-vertical:before {
    content: "\EB28";
}

.psi-double-circle:before {
    content: "\EB29";
}

.psi-double-tap:before {
    content: "\EB2A";
}

.psi-doughnut:before {
    content: "\EB2B";
}

.psi-dove:before {
    content: "\EB2C";
}

.psi-down:before {
    content: "\EB2D";
}

.psi-down-2:before {
    content: "\EB2E";
}

.psi-down-3:before {
    content: "\EB2F";
}

.psi-down-4:before {
    content: "\EB30";
}

.psi-download:before {
    content: "\EB31";
}

.psi-download-2:before {
    content: "\EB32";
}

.psi-download-from-cloud:before {
    content: "\EB33";
}

.psi-download-window:before {
    content: "\EB34";
}

.psi-downward:before {
    content: "\EB35";
}

.psi-drag:before {
    content: "\EB36";
}

.psi-drag-down:before {
    content: "\EB37";
}

.psi-drag-left:before {
    content: "\EB38";
}

.psi-drag-right:before {
    content: "\EB39";
}

.psi-drag-up:before {
    content: "\EB3A";
}

.psi-dress:before {
    content: "\EB3B";
}

.psi-dribbble:before {
    content: "\EB3C";
}

.psi-drill:before {
    content: "\EB3D";
}

.psi-drill-2:before {
    content: "\EB3E";
}

.psi-drop:before {
    content: "\EB3F";
}

.psi-dropbox:before {
    content: "\EB40";
}

.psi-drum:before {
    content: "\EB41";
}

.psi-dry:before {
    content: "\EB42";
}

.psi-duck:before {
    content: "\EB43";
}

.psi-dumbbell:before {
    content: "\EB44";
}

.psi-duplicate-layer:before {
    content: "\EB45";
}

.psi-duplicate-window:before {
    content: "\EB46";
}

.psi-dvd:before {
    content: "\EB47";
}

.psi-eagle:before {
    content: "\EB48";
}

.psi-ear:before {
    content: "\EB49";
}

.psi-earphones:before {
    content: "\EB4A";
}

.psi-earphones-2:before {
    content: "\EB4B";
}

.psi-eci-icon:before {
    content: "\EB4C";
}

.psi-edit:before {
    content: "\EB4D";
}

.psi-edit-map:before {
    content: "\EB4E";
}

.psi-eggs:before {
    content: "\EB4F";
}

.psi-egypt:before {
    content: "\EB50";
}

.psi-eifel-tower:before {
    content: "\EB51";
}

.psi-eject:before {
    content: "\EB52";
}

.psi-eject-2:before {
    content: "\EB53";
}

.psi-el-castillo:before {
    content: "\EB54";
}

.psi-elbow:before {
    content: "\EB55";
}

.psi-electric-guitar:before {
    content: "\EB56";
}

.psi-electricity:before {
    content: "\EB57";
}

.psi-elephant:before {
    content: "\EB58";
}

.psi-email:before {
    content: "\EB59";
}

.psi-embassy:before {
    content: "\EB5A";
}

.psi-empire-state-building:before {
    content: "\EB5B";
}

.psi-empty-box:before {
    content: "\EB5C";
}

.psi-end:before {
    content: "\EB5D";
}

.psi-end-2:before {
    content: "\EB5E";
}

.psi-end-3:before {
    content: "\EB5F";
}

.psi-endways:before {
    content: "\EB60";
}

.psi-engineering:before {
    content: "\EB61";
}

.psi-envelope:before {
    content: "\EB62";
}

.psi-envelope-2:before {
    content: "\EB63";
}

.psi-environmental:before {
    content: "\EB64";
}

.psi-environmental-2:before {
    content: "\EB65";
}

.psi-environmental-3:before {
    content: "\EB66";
}

.psi-equalizer:before {
    content: "\EB67";
}

.psi-eraser:before {
    content: "\EB68";
}

.psi-eraser-2:before {
    content: "\EB69";
}

.psi-eraser-3:before {
    content: "\EB6A";
}

.psi-error-404-window:before {
    content: "\EB6B";
}

.psi-euro:before {
    content: "\EB6C";
}

.psi-euro-sign:before {
    content: "\EB6D";
}

.psi-euro-sign-2:before {
    content: "\EB6E";
}

.psi-evernote:before {
    content: "\EB6F";
}

.psi-evil:before {
    content: "\EB70";
}

.psi-exclamation:before {
    content: "\EB71";
}

.psi-explode:before {
    content: "\EB72";
}

.psi-eye:before {
    content: "\EB73";
}

.psi-eye-2:before {
    content: "\EB74";
}

.psi-eye-blind:before {
    content: "\EB75";
}

.psi-eye-invisible:before {
    content: "\EB76";
}

.psi-eye-scan:before {
    content: "\EB77";
}

.psi-eye-visible:before {
    content: "\EB78";
}

.psi-eyebrow:before {
    content: "\EB79";
}

.psi-eyebrow-2:before {
    content: "\EB7A";
}

.psi-eyebrow-3:before {
    content: "\EB7B";
}

.psi-eyeglasses-smiley:before {
    content: "\EB7C";
}

.psi-eyeglasses-smiley-2:before {
    content: "\EB7D";
}

.psi-face-style:before {
    content: "\EB7E";
}

.psi-face-style-2:before {
    content: "\EB7F";
}

.psi-face-style-3:before {
    content: "\EB80";
}

.psi-face-style-4:before {
    content: "\EB81";
}

.psi-face-style-5:before {
    content: "\EB82";
}

.psi-face-style-6:before {
    content: "\EB83";
}

.psi-facebook:before {
    content: "\EB84";
}

.psi-facebook-2:before {
    content: "\EB85";
}

.psi-factory:before {
    content: "\EB86";
}

.psi-factory-2:before {
    content: "\EB87";
}

.psi-fahrenheit:before {
    content: "\EB88";
}

.psi-family-sign:before {
    content: "\EB89";
}

.psi-fan:before {
    content: "\EB8A";
}

.psi-farmer:before {
    content: "\EB8B";
}

.psi-fashion:before {
    content: "\EB8C";
}

.psi-favorite-window:before {
    content: "\EB8D";
}

.psi-fax:before {
    content: "\EB8E";
}

.psi-feather:before {
    content: "\EB8F";
}

.psi-feedburner:before {
    content: "\EB90";
}

.psi-female:before {
    content: "\EB91";
}

.psi-female-2:before {
    content: "\EB92";
}

.psi-female-sign:before {
    content: "\EB93";
}

.psi-file:before {
    content: "\EB94";
}

.psi-file-add:before {
    content: "\EB95";
}

.psi-file-block:before {
    content: "\EB96";
}

.psi-file-bookmark:before {
    content: "\EB97";
}

.psi-file-chart:before {
    content: "\EB98";
}

.psi-file-clipboard:before {
    content: "\EB99";
}

.psi-file-clipboard-file-text:before {
    content: "\EB9A";
}

.psi-file-clipboard-text-image:before {
    content: "\EB9B";
}

.psi-file-cloud:before {
    content: "\EB9C";
}

.psi-file-copy:before {
    content: "\EB9D";
}

.psi-file-copy-2:before {
    content: "\EB9E";
}

.psi-file-csv:before {
    content: "\EB9F";
}

.psi-file-delete:before {
    content: "\EBA0";
}

.psi-file-download:before {
    content: "\EBA1";
}

.psi-file-edit:before {
    content: "\EBA2";
}

.psi-file-excel:before {
    content: "\EBA3";
}

.psi-file-favorite:before {
    content: "\EBA4";
}

.psi-file-fire:before {
    content: "\EBA5";
}

.psi-file-graph:before {
    content: "\EBA6";
}

.psi-file-hide:before {
    content: "\EBA7";
}

.psi-file-horizontal:before {
    content: "\EBA8";
}

.psi-file-horizontal-text:before {
    content: "\EBA9";
}

.psi-file-html:before {
    content: "\EBAA";
}

.psi-file-jpg:before {
    content: "\EBAB";
}

.psi-file-link:before {
    content: "\EBAC";
}

.psi-file-loading:before {
    content: "\EBAD";
}

.psi-file-lock:before {
    content: "\EBAE";
}

.psi-file-love:before {
    content: "\EBAF";
}

.psi-file-music:before {
    content: "\EBB0";
}

.psi-file-network:before {
    content: "\EBB1";
}

.psi-file-pictures:before {
    content: "\EBB2";
}

.psi-file-pie:before {
    content: "\EBB3";
}

.psi-file-presentation:before {
    content: "\EBB4";
}

.psi-file-refresh:before {
    content: "\EBB5";
}

.psi-file-remove:before {
    content: "\EBB6";
}

.psi-file-search:before {
    content: "\EBB7";
}

.psi-file-settings:before {
    content: "\EBB8";
}

.psi-file-share:before {
    content: "\EBB9";
}

.psi-file-text-image:before {
    content: "\EBBA";
}

.psi-file-trash:before {
    content: "\EBBB";
}

.psi-file-txt:before {
    content: "\EBBC";
}

.psi-file-upload:before {
    content: "\EBBD";
}

.psi-file-video:before {
    content: "\EBBE";
}

.psi-file-word:before {
    content: "\EBBF";
}

.psi-file-zip:before {
    content: "\EBC0";
}

.psi-files:before {
    content: "\EBC1";
}

.psi-film:before {
    content: "\EBC2";
}

.psi-film-board:before {
    content: "\EBC3";
}

.psi-film-cartridge:before {
    content: "\EBC4";
}

.psi-film-strip:before {
    content: "\EBC5";
}

.psi-film-video:before {
    content: "\EBC6";
}

.psi-filter:before {
    content: "\EBC7";
}

.psi-filter-2:before {
    content: "\EBC8";
}

.psi-financial:before {
    content: "\EBC9";
}

.psi-find-user:before {
    content: "\EBCA";
}

.psi-finger:before {
    content: "\EBCB";
}

.psi-finger-drag-four-sides:before {
    content: "\EBCC";
}

.psi-finger-drag-two-sides:before {
    content: "\EBCD";
}

.psi-finger-print:before {
    content: "\EBCE";
}

.psi-fingerprint:before {
    content: "\EBCF";
}

.psi-fingerprint-2:before {
    content: "\EBD0";
}

.psi-fire-flame:before {
    content: "\EBD1";
}

.psi-fire-flame-2:before {
    content: "\EBD2";
}

.psi-fire-hydrant:before {
    content: "\EBD3";
}

.psi-fire-station:before {
    content: "\EBD4";
}

.psi-firefox:before {
    content: "\EBD5";
}

.psi-firewall:before {
    content: "\EBD6";
}

.psi-first:before {
    content: "\EBD7";
}

.psi-first-aid:before {
    content: "\EBD8";
}

.psi-fish:before {
    content: "\EBD9";
}

.psi-fish-food:before {
    content: "\EBDA";
}

.psi-fit-to:before {
    content: "\EBDB";
}

.psi-fit-to-2:before {
    content: "\EBDC";
}

.psi-five-fingers:before {
    content: "\EBDD";
}

.psi-five-fingers-drag:before {
    content: "\EBDE";
}

.psi-five-fingers-drag-2:before {
    content: "\EBDF";
}

.psi-five-fingers-touch:before {
    content: "\EBE0";
}

.psi-flag:before {
    content: "\EBE1";
}

.psi-flag-2:before {
    content: "\EBE2";
}

.psi-flag-3:before {
    content: "\EBE3";
}

.psi-flag-4:before {
    content: "\EBE4";
}

.psi-flag-5:before {
    content: "\EBE5";
}

.psi-flag-6:before {
    content: "\EBE6";
}

.psi-flamingo:before {
    content: "\EBE7";
}

.psi-flash:before {
    content: "\EBE8";
}

.psi-flash-2:before {
    content: "\EBE9";
}

.psi-flash-video:before {
    content: "\EBEA";
}

.psi-flashlight:before {
    content: "\EBEB";
}

.psi-flask:before {
    content: "\EBEC";
}

.psi-flask-2:before {
    content: "\EBED";
}

.psi-flick:before {
    content: "\EBEE";
}

.psi-flickr:before {
    content: "\EBEF";
}

.psi-flowerpot:before {
    content: "\EBF0";
}

.psi-fluorescent:before {
    content: "\EBF1";
}

.psi-fog-day:before {
    content: "\EBF2";
}

.psi-fog-night:before {
    content: "\EBF3";
}

.psi-folder:before {
    content: "\EBF4";
}

.psi-folder-add:before {
    content: "\EBF5";
}

.psi-folder-archive:before {
    content: "\EBF6";
}

.psi-folder-binder:before {
    content: "\EBF7";
}

.psi-folder-binder-2:before {
    content: "\EBF8";
}

.psi-folder-block:before {
    content: "\EBF9";
}

.psi-folder-bookmark:before {
    content: "\EBFA";
}

.psi-folder-close:before {
    content: "\EBFB";
}

.psi-folder-cloud:before {
    content: "\EBFC";
}

.psi-folder-delete:before {
    content: "\EBFD";
}

.psi-folder-download:before {
    content: "\EBFE";
}

.psi-folder-edit:before {
    content: "\EBFF";
}

.psi-folder-favorite:before {
    content: "\EC00";
}

.psi-folder-fire:before {
    content: "\EC01";
}

.psi-folder-hide:before {
    content: "\EC02";
}

.psi-folder-link:before {
    content: "\EC03";
}

.psi-folder-loading:before {
    content: "\EC04";
}

.psi-folder-lock:before {
    content: "\EC05";
}

.psi-folder-love:before {
    content: "\EC06";
}

.psi-folder-music:before {
    content: "\EC07";
}

.psi-folder-network:before {
    content: "\EC08";
}

.psi-folder-open:before {
    content: "\EC09";
}

.psi-folder-open-2:before {
    content: "\EC0A";
}

.psi-folder-organizing:before {
    content: "\EC0B";
}

.psi-folder-pictures:before {
    content: "\EC0C";
}

.psi-folder-refresh:before {
    content: "\EC0D";
}

.psi-folder-remove:before {
    content: "\EC0E";
}

.psi-folder-search:before {
    content: "\EC0F";
}

.psi-folder-settings:before {
    content: "\EC10";
}

.psi-folder-share:before {
    content: "\EC11";
}

.psi-folder-trash:before {
    content: "\EC12";
}

.psi-folder-upload:before {
    content: "\EC13";
}

.psi-folder-video:before {
    content: "\EC14";
}

.psi-folder-with-document:before {
    content: "\EC15";
}

.psi-folder-zip:before {
    content: "\EC16";
}

.psi-folders:before {
    content: "\EC17";
}

.psi-font-color:before {
    content: "\EC18";
}

.psi-font-name:before {
    content: "\EC19";
}

.psi-font-size:before {
    content: "\EC1A";
}

.psi-font-style:before {
    content: "\EC1B";
}

.psi-font-style-subscript:before {
    content: "\EC1C";
}

.psi-font-style-superscript:before {
    content: "\EC1D";
}

.psi-font-window:before {
    content: "\EC1E";
}

.psi-foot:before {
    content: "\EC1F";
}

.psi-foot-2:before {
    content: "\EC20";
}

.psi-football:before {
    content: "\EC21";
}

.psi-football-2:before {
    content: "\EC22";
}

.psi-footprint:before {
    content: "\EC23";
}

.psi-footprint-2:before {
    content: "\EC24";
}

.psi-footprint-3:before {
    content: "\EC25";
}

.psi-forest:before {
    content: "\EC26";
}

.psi-fork:before {
    content: "\EC27";
}

.psi-formspring:before {
    content: "\EC28";
}

.psi-formula:before {
    content: "\EC29";
}

.psi-forward:before {
    content: "\EC2A";
}

.psi-fountain-pen:before {
    content: "\EC2B";
}

.psi-four-fingers:before {
    content: "\EC2C";
}

.psi-four-fingers-drag:before {
    content: "\EC2D";
}

.psi-four-fingers-drag-2:before {
    content: "\EC2E";
}

.psi-four-fingers-touch:before {
    content: "\EC2F";
}

.psi-foursquare:before {
    content: "\EC30";
}

.psi-fox:before {
    content: "\EC31";
}

.psi-frankenstein:before {
    content: "\EC32";
}

.psi-french-fries:before {
    content: "\EC33";
}

.psi-friendfeed:before {
    content: "\EC34";
}

.psi-friendster:before {
    content: "\EC35";
}

.psi-frog:before {
    content: "\EC36";
}

.psi-fruits:before {
    content: "\EC37";
}

.psi-fuel:before {
    content: "\EC38";
}

.psi-full-bag:before {
    content: "\EC39";
}

.psi-full-basket:before {
    content: "\EC3A";
}

.psi-full-cart:before {
    content: "\EC3B";
}

.psi-full-moon:before {
    content: "\EC3C";
}

.psi-full-screen:before {
    content: "\EC3D";
}

.psi-full-screen-2:before {
    content: "\EC3E";
}

.psi-full-view:before {
    content: "\EC3F";
}

.psi-full-view-2:before {
    content: "\EC40";
}

.psi-full-view-window:before {
    content: "\EC41";
}

.psi-function:before {
    content: "\EC42";
}

.psi-funky:before {
    content: "\EC43";
}

.psi-funny-bicycle:before {
    content: "\EC44";
}

.psi-furl:before {
    content: "\EC45";
}

.psi-gamepad:before {
    content: "\EC46";
}

.psi-gamepad-2:before {
    content: "\EC47";
}

.psi-gas-pump:before {
    content: "\EC48";
}

.psi-gauge:before {
    content: "\EC49";
}

.psi-gauge-2:before {
    content: "\EC4A";
}

.psi-gay:before {
    content: "\EC4B";
}

.psi-gear:before {
    content: "\EC4C";
}

.psi-gear-2:before {
    content: "\EC4D";
}

.psi-gears:before {
    content: "\EC4E";
}

.psi-gears-2:before {
    content: "\EC4F";
}

.psi-geek:before {
    content: "\EC50";
}

.psi-geek-2:before {
    content: "\EC51";
}

.psi-gemini:before {
    content: "\EC52";
}

.psi-gemini-2:before {
    content: "\EC53";
}

.psi-genius:before {
    content: "\EC54";
}

.psi-gentleman:before {
    content: "\EC55";
}

.psi-geo:before {
    content: "\EC56";
}

.psi-geo-2:before {
    content: "\EC57";
}

.psi-geo-2-close:before {
    content: "\EC58";
}

.psi-geo-2-love:before {
    content: "\EC59";
}

.psi-geo-2-min:before {
    content: "\EC5A";
}

.psi-geo-2-number:before {
    content: "\EC5B";
}

.psi-geo-2-plus:before {
    content: "\EC5C";
}

.psi-geo-2-star:before {
    content: "\EC5D";
}

.psi-geo-3:before {
    content: "\EC5E";
}

.psi-geo-3-close:before {
    content: "\EC5F";
}

.psi-geo-3-love:before {
    content: "\EC60";
}

.psi-geo-3-min:before {
    content: "\EC61";
}

.psi-geo-3-number:before {
    content: "\EC62";
}

.psi-geo-3-plus:before {
    content: "\EC63";
}

.psi-geo-3-star:before {
    content: "\EC64";
}

.psi-geo-close:before {
    content: "\EC65";
}

.psi-geo-love:before {
    content: "\EC66";
}

.psi-geo-min:before {
    content: "\EC67";
}

.psi-geo-number:before {
    content: "\EC68";
}

.psi-geo-plus:before {
    content: "\EC69";
}

.psi-geo-star:before {
    content: "\EC6A";
}

.psi-ghost:before {
    content: "\EC6B";
}

.psi-gift-box:before {
    content: "\EC6C";
}

.psi-giraffe:before {
    content: "\EC6D";
}

.psi-girl:before {
    content: "\EC6E";
}

.psi-glass-water:before {
    content: "\EC6F";
}

.psi-glasses:before {
    content: "\EC70";
}

.psi-glasses-2:before {
    content: "\EC71";
}

.psi-glasses-3:before {
    content: "\EC72";
}

.psi-global-position:before {
    content: "\EC73";
}

.psi-globe:before {
    content: "\EC74";
}

.psi-globe-2:before {
    content: "\EC75";
}

.psi-gloves:before {
    content: "\EC76";
}

.psi-go-bottom:before {
    content: "\EC77";
}

.psi-go-top:before {
    content: "\EC78";
}

.psi-goggles:before {
    content: "\EC79";
}

.psi-golf:before {
    content: "\EC7A";
}

.psi-golf-2:before {
    content: "\EC7B";
}

.psi-google:before {
    content: "\EC7C";
}

.psi-google-buzz:before {
    content: "\EC7D";
}

.psi-google-drive:before {
    content: "\EC7E";
}

.psi-google-play:before {
    content: "\EC7F";
}

.psi-google-plus:before {
    content: "\EC80";
}

.psi-gopro:before {
    content: "\EC81";
}

.psi-gorilla:before {
    content: "\EC82";
}

.psi-gowalla:before {
    content: "\EC83";
}

.psi-grave:before {
    content: "\EC84";
}

.psi-graveyard:before {
    content: "\EC85";
}

.psi-greece:before {
    content: "\EC86";
}

.psi-green-energy:before {
    content: "\EC87";
}

.psi-green-house:before {
    content: "\EC88";
}

.psi-guitar:before {
    content: "\EC89";
}

.psi-gun:before {
    content: "\EC8A";
}

.psi-gun-2:before {
    content: "\EC8B";
}

.psi-gun-3:before {
    content: "\EC8C";
}

.psi-gymnastics:before {
    content: "\EC8D";
}

.psi-hair:before {
    content: "\EC8E";
}

.psi-hair-2:before {
    content: "\EC8F";
}

.psi-hair-3:before {
    content: "\EC90";
}

.psi-hair-4:before {
    content: "\EC91";
}

.psi-half-moon:before {
    content: "\EC92";
}

.psi-halloween-halfmoon:before {
    content: "\EC93";
}

.psi-halloween-moon:before {
    content: "\EC94";
}

.psi-hamburger:before {
    content: "\EC95";
}

.psi-hammer:before {
    content: "\EC96";
}

.psi-hand:before {
    content: "\EC97";
}

.psi-hand-touch:before {
    content: "\EC98";
}

.psi-hand-touch-2:before {
    content: "\EC99";
}

.psi-hand-touch-smartphone:before {
    content: "\EC9A";
}

.psi-hands:before {
    content: "\EC9B";
}

.psi-handshake:before {
    content: "\EC9C";
}

.psi-hanger:before {
    content: "\EC9D";
}

.psi-happy:before {
    content: "\EC9E";
}

.psi-hat:before {
    content: "\EC9F";
}

.psi-hat-2:before {
    content: "\ECA0";
}

.psi-haunted-house:before {
    content: "\ECA1";
}

.psi-hd:before {
    content: "\ECA2";
}

.psi-hd-video:before {
    content: "\ECA3";
}

.psi-hdd:before {
    content: "\ECA4";
}

.psi-headphone:before {
    content: "\ECA5";
}

.psi-headphones:before {
    content: "\ECA6";
}

.psi-headset:before {
    content: "\ECA7";
}

.psi-heart:before {
    content: "\ECA8";
}

.psi-heart-2:before {
    content: "\ECA9";
}

.psi-heels:before {
    content: "\ECAA";
}

.psi-heels-2:before {
    content: "\ECAB";
}

.psi-height-window:before {
    content: "\ECAC";
}

.psi-helicopter:before {
    content: "\ECAD";
}

.psi-helicopter-2:before {
    content: "\ECAE";
}

.psi-helix-2:before {
    content: "\ECAF";
}

.psi-hello:before {
    content: "\ECB0";
}

.psi-helmet:before {
    content: "\ECB1";
}

.psi-helmet-2:before {
    content: "\ECB2";
}

.psi-helmet-3:before {
    content: "\ECB3";
}

.psi-hippo:before {
    content: "\ECB4";
}

.psi-hipster-glasses:before {
    content: "\ECB5";
}

.psi-hipster-glasses-2:before {
    content: "\ECB6";
}

.psi-hipster-glasses-3:before {
    content: "\ECB7";
}

.psi-hipster-headphones:before {
    content: "\ECB8";
}

.psi-hipster-man:before {
    content: "\ECB9";
}

.psi-hipster-man-2:before {
    content: "\ECBA";
}

.psi-hipster-man-3:before {
    content: "\ECBB";
}

.psi-hipster-sunglasses:before {
    content: "\ECBC";
}

.psi-hipster-sunglasses-2:before {
    content: "\ECBD";
}

.psi-hipster-sunglasses-3:before {
    content: "\ECBE";
}

.psi-hokey:before {
    content: "\ECBF";
}

.psi-holly:before {
    content: "\ECC0";
}

.psi-home:before {
    content: "\ECC1";
}

.psi-home-2:before {
    content: "\ECC2";
}

.psi-home-3:before {
    content: "\ECC3";
}

.psi-home-4:before {
    content: "\ECC4";
}

.psi-home-5:before {
    content: "\ECC5";
}

.psi-home-window:before {
    content: "\ECC6";
}

.psi-honey:before {
    content: "\ECC7";
}

.psi-hongkong:before {
    content: "\ECC8";
}

.psi-hoodie:before {
    content: "\ECC9";
}

.psi-horror:before {
    content: "\ECCA";
}

.psi-horse:before {
    content: "\ECCB";
}

.psi-hospital:before {
    content: "\ECCC";
}

.psi-hospital-2:before {
    content: "\ECCD";
}

.psi-hot-dog:before {
    content: "\ECCE";
}

.psi-hotel:before {
    content: "\ECCF";
}

.psi-hour:before {
    content: "\ECD0";
}

.psi-hub:before {
    content: "\ECD1";
}

.psi-humor:before {
    content: "\ECD2";
}

.psi-hurt:before {
    content: "\ECD3";
}

.psi-ice-cream:before {
    content: "\ECD4";
}

.psi-icq:before {
    content: "\ECD5";
}

.psi-id-2:before {
    content: "\ECD6";
}

.psi-id-3:before {
    content: "\ECD7";
}

.psi-id-card:before {
    content: "\ECD8";
}

.psi-idea:before {
    content: "\ECD9";
}

.psi-idea-2:before {
    content: "\ECDA";
}

.psi-idea-3:before {
    content: "\ECDB";
}

.psi-idea-4:before {
    content: "\ECDC";
}

.psi-idea-5:before {
    content: "\ECDD";
}

.psi-identification-badge:before {
    content: "\ECDE";
}

.psi-imdb:before {
    content: "\ECDF";
}

.psi-inbox:before {
    content: "\ECE0";
}

.psi-inbox-empty:before {
    content: "\ECE1";
}

.psi-inbox-forward:before {
    content: "\ECE2";
}

.psi-inbox-full:before {
    content: "\ECE3";
}

.psi-inbox-into:before {
    content: "\ECE4";
}

.psi-inbox-out:before {
    content: "\ECE5";
}

.psi-inbox-reply:before {
    content: "\ECE6";
}

.psi-increase-indent:before {
    content: "\ECE7";
}

.psi-indent-first-line:before {
    content: "\ECE8";
}

.psi-indent-left-margin:before {
    content: "\ECE9";
}

.psi-indent-right-margin:before {
    content: "\ECEA";
}

.psi-india:before {
    content: "\ECEB";
}

.psi-infinity:before {
    content: "\ECEC";
}

.psi-info-window:before {
    content: "\ECED";
}

.psi-information:before {
    content: "\ECEE";
}

.psi-instagram:before {
    content: "\ECEF";
}

.psi-internet:before {
    content: "\ECF0";
}

.psi-internet-2:before {
    content: "\ECF1";
}

.psi-internet-explorer:before {
    content: "\ECF2";
}

.psi-internet-smiley:before {
    content: "\ECF3";
}

.psi-ios-apple:before {
    content: "\ECF4";
}

.psi-israel:before {
    content: "\ECF5";
}

.psi-italic-text:before {
    content: "\ECF6";
}

.psi-jacket:before {
    content: "\ECF7";
}

.psi-jacket-2:before {
    content: "\ECF8";
}

.psi-jamaica:before {
    content: "\ECF9";
}

.psi-japan:before {
    content: "\ECFA";
}

.psi-japanese-gate:before {
    content: "\ECFB";
}

.psi-jeans:before {
    content: "\ECFC";
}

.psi-jeep:before {
    content: "\ECFD";
}

.psi-jeep-2:before {
    content: "\ECFE";
}

.psi-jet:before {
    content: "\ECFF";
}

.psi-joystick:before {
    content: "\ED00";
}

.psi-juice:before {
    content: "\ED01";
}

.psi-jump-rope:before {
    content: "\ED02";
}

.psi-kangaroo:before {
    content: "\ED03";
}

.psi-kenya:before {
    content: "\ED04";
}

.psi-key:before {
    content: "\ED05";
}

.psi-key-2:before {
    content: "\ED06";
}

.psi-key-3:before {
    content: "\ED07";
}

.psi-key-lock:before {
    content: "\ED08";
}

.psi-keyboard:before {
    content: "\ED09";
}

.psi-keyboard-3:before {
    content: "\ED0A";
}

.psi-keypad:before {
    content: "\ED0B";
}

.psi-king:before {
    content: "\ED0C";
}

.psi-king-2:before {
    content: "\ED0D";
}

.psi-kiss:before {
    content: "\ED0E";
}

.psi-knee:before {
    content: "\ED0F";
}

.psi-knife:before {
    content: "\ED10";
}

.psi-knife-2:before {
    content: "\ED11";
}

.psi-knight:before {
    content: "\ED12";
}

.psi-koala:before {
    content: "\ED13";
}

.psi-korea:before {
    content: "\ED14";
}

.psi-lamp:before {
    content: "\ED15";
}

.psi-landscape:before {
    content: "\ED16";
}

.psi-landscape-2:before {
    content: "\ED17";
}

.psi-lantern:before {
    content: "\ED18";
}

.psi-laptop:before {
    content: "\ED19";
}

.psi-laptop-2:before {
    content: "\ED1A";
}

.psi-laptop-3:before {
    content: "\ED1B";
}

.psi-laptop-phone:before {
    content: "\ED1C";
}

.psi-laptop-secure:before {
    content: "\ED1D";
}

.psi-laptop-tablet:before {
    content: "\ED1E";
}

.psi-laser:before {
    content: "\ED1F";
}

.psi-last:before {
    content: "\ED20";
}

.psi-last-fm:before {
    content: "\ED21";
}

.psi-laughing:before {
    content: "\ED22";
}

.psi-layer-backward:before {
    content: "\ED23";
}

.psi-layer-forward:before {
    content: "\ED24";
}

.psi-layout-grid:before {
    content: "\ED25";
}

.psi-leafs:before {
    content: "\ED26";
}

.psi-leafs-2:before {
    content: "\ED27";
}

.psi-leaning-tower:before {
    content: "\ED28";
}

.psi-left:before {
    content: "\ED29";
}

.psi-left-2:before {
    content: "\ED2A";
}

.psi-left-3:before {
    content: "\ED2B";
}

.psi-left-4:before {
    content: "\ED2C";
}

.psi-left-right:before {
    content: "\ED2D";
}

.psi-left-right-3:before {
    content: "\ED2E";
}

.psi-left-to-right:before {
    content: "\ED2F";
}

.psi-leg:before {
    content: "\ED30";
}

.psi-leg-2:before {
    content: "\ED31";
}

.psi-lego:before {
    content: "\ED32";
}

.psi-lemon:before {
    content: "\ED33";
}

.psi-len:before {
    content: "\ED34";
}

.psi-len-2:before {
    content: "\ED35";
}

.psi-len-3:before {
    content: "\ED36";
}

.psi-leo:before {
    content: "\ED37";
}

.psi-leo-2:before {
    content: "\ED38";
}

.psi-leopard:before {
    content: "\ED39";
}

.psi-letter-close:before {
    content: "\ED3A";
}

.psi-letter-open:before {
    content: "\ED3B";
}

.psi-letter-sent:before {
    content: "\ED3C";
}

.psi-libra:before {
    content: "\ED3D";
}

.psi-libra-2:before {
    content: "\ED3E";
}

.psi-library:before {
    content: "\ED3F";
}

.psi-library-2:before {
    content: "\ED40";
}

.psi-life-jacket:before {
    content: "\ED41";
}

.psi-lifesaver:before {
    content: "\ED42";
}

.psi-light-bulb:before {
    content: "\ED43";
}

.psi-light-bulb-2:before {
    content: "\ED44";
}

.psi-light-bulb-leaf:before {
    content: "\ED45";
}

.psi-lighthouse:before {
    content: "\ED46";
}

.psi-like:before {
    content: "\ED47";
}

.psi-like-2:before {
    content: "\ED48";
}

.psi-line-chart:before {
    content: "\ED49";
}

.psi-line-chart-2:before {
    content: "\ED4A";
}

.psi-line-chart-3:before {
    content: "\ED4B";
}

.psi-line-chart-4:before {
    content: "\ED4C";
}

.psi-line-spacing:before {
    content: "\ED4D";
}

.psi-line-spacing-text:before {
    content: "\ED4E";
}

.psi-link:before {
    content: "\ED4F";
}

.psi-link-2:before {
    content: "\ED50";
}

.psi-linkedin:before {
    content: "\ED51";
}

.psi-linkedin-2:before {
    content: "\ED52";
}

.psi-linux:before {
    content: "\ED53";
}

.psi-lion:before {
    content: "\ED54";
}

.psi-livejournal:before {
    content: "\ED55";
}

.psi-loading:before {
    content: "\ED56";
}

.psi-loading-2:before {
    content: "\ED57";
}

.psi-loading-3:before {
    content: "\ED58";
}

.psi-loading-window:before {
    content: "\ED59";
}

.psi-location:before {
    content: "\ED5A";
}

.psi-location-2:before {
    content: "\ED5B";
}

.psi-lock:before {
    content: "\ED5C";
}

.psi-lock-2:before {
    content: "\ED5D";
}

.psi-lock-3:before {
    content: "\ED5E";
}

.psi-lock-user:before {
    content: "\ED5F";
}

.psi-lock-window:before {
    content: "\ED60";
}

.psi-lollipop:before {
    content: "\ED61";
}

.psi-lollipop-2:before {
    content: "\ED62";
}

.psi-lollipop-3:before {
    content: "\ED63";
}

.psi-loop:before {
    content: "\ED64";
}

.psi-loud:before {
    content: "\ED65";
}

.psi-loudspeaker:before {
    content: "\ED66";
}

.psi-love:before {
    content: "\ED67";
}

.psi-love-2:before {
    content: "\ED68";
}

.psi-love-user:before {
    content: "\ED69";
}

.psi-love-window:before {
    content: "\ED6A";
}

.psi-lowercase-text:before {
    content: "\ED6B";
}

.psi-luggage:before {
    content: "\ED6C";
}

.psi-luggage-2:before {
    content: "\ED6D";
}

.psi-macro:before {
    content: "\ED6E";
}

.psi-magic-wand:before {
    content: "\ED6F";
}

.psi-magnet:before {
    content: "\ED70";
}

.psi-magnifi-glass:before {
    content: "\ED71";
}

.psi-magnifi-glass-plus:before {
    content: "\ED72";
}

.psi-magnifi-glass-min:before {
    content: "\ED73";
}

.psi-mail:before {
    content: "\ED74";
}

.psi-mail-2:before {
    content: "\ED75";
}

.psi-mail-3:before {
    content: "\ED76";
}

.psi-mail-add:before {
    content: "\ED77";
}

.psi-mail-attachment:before {
    content: "\ED78";
}

.psi-mail-block:before {
    content: "\ED79";
}

.psi-mail-delete:before {
    content: "\ED7A";
}

.psi-mail-favorite:before {
    content: "\ED7B";
}

.psi-mail-forward:before {
    content: "\ED7C";
}

.psi-mail-gallery:before {
    content: "\ED7D";
}

.psi-mail-inbox:before {
    content: "\ED7E";
}

.psi-mail-link:before {
    content: "\ED7F";
}

.psi-mail-lock:before {
    content: "\ED80";
}

.psi-mail-love:before {
    content: "\ED81";
}

.psi-mail-money:before {
    content: "\ED82";
}

.psi-mail-open:before {
    content: "\ED83";
}

.psi-mail-outbox:before {
    content: "\ED84";
}

.psi-mail-password:before {
    content: "\ED85";
}

.psi-mail-photo:before {
    content: "\ED86";
}

.psi-mail-read:before {
    content: "\ED87";
}

.psi-mail-remove:before {
    content: "\ED88";
}

.psi-mail-reply:before {
    content: "\ED89";
}

.psi-mail-reply-all:before {
    content: "\ED8A";
}

.psi-mail-search:before {
    content: "\ED8B";
}

.psi-mail-send:before {
    content: "\ED8C";
}

.psi-mail-settings:before {
    content: "\ED8D";
}

.psi-mail-unread:before {
    content: "\ED8E";
}

.psi-mail-video:before {
    content: "\ED8F";
}

.psi-mail-with-at-sign:before {
    content: "\ED90";
}

.psi-mail-with-cursor:before {
    content: "\ED91";
}

.psi-mailbox-empty:before {
    content: "\ED92";
}

.psi-mailbox-full:before {
    content: "\ED93";
}

.psi-male:before {
    content: "\ED94";
}

.psi-male+female:before {
    content: "\ED95";
}

.psi-male-2:before {
    content: "\ED96";
}

.psi-male-female:before {
    content: "\ED97";
}

.psi-male-sign:before {
    content: "\ED98";
}

.psi-man-sign:before {
    content: "\ED99";
}

.psi-management:before {
    content: "\ED9A";
}

.psi-mans-underwear:before {
    content: "\ED9B";
}

.psi-mans-underwear-2:before {
    content: "\ED9C";
}

.psi-map:before {
    content: "\ED9D";
}

.psi-map-2:before {
    content: "\ED9E";
}

.psi-map-marker:before {
    content: "\ED9F";
}

.psi-map-marker-2:before {
    content: "\EDA0";
}

.psi-map-marker-3:before {
    content: "\EDA1";
}

.psi-marker:before {
    content: "\EDA2";
}

.psi-marker-2:before {
    content: "\EDA3";
}

.psi-marker-3:before {
    content: "\EDA4";
}

.psi-martini-glass:before {
    content: "\EDA5";
}

.psi-mask:before {
    content: "\EDA6";
}

.psi-master-card:before {
    content: "\EDA7";
}

.psi-maximize:before {
    content: "\EDA8";
}

.psi-maximize-2:before {
    content: "\EDA9";
}

.psi-maximize-3:before {
    content: "\EDAA";
}

.psi-maximize-window:before {
    content: "\EDAB";
}

.psi-medal:before {
    content: "\EDAC";
}

.psi-medal-2:before {
    content: "\EDAD";
}

.psi-medal-3:before {
    content: "\EDAE";
}

.psi-medical-sign:before {
    content: "\EDAF";
}

.psi-medicine:before {
    content: "\EDB0";
}

.psi-medicine-2:before {
    content: "\EDB1";
}

.psi-medicine-3:before {
    content: "\EDB2";
}

.psi-megaphone:before {
    content: "\EDB3";
}

.psi-memory-card:before {
    content: "\EDB4";
}

.psi-memory-card-2:before {
    content: "\EDB5";
}

.psi-memory-card-3:before {
    content: "\EDB6";
}

.psi-men:before {
    content: "\EDB7";
}

.psi-menorah:before {
    content: "\EDB8";
}

.psi-mens:before {
    content: "\EDB9";
}

.psi-metacafe:before {
    content: "\EDBA";
}

.psi-mexico:before {
    content: "\EDBB";
}

.psi-mic:before {
    content: "\EDBC";
}

.psi-microphone:before {
    content: "\EDBD";
}

.psi-microphone-2:before {
    content: "\EDBE";
}

.psi-microphone-3:before {
    content: "\EDBF";
}

.psi-microphone-4:before {
    content: "\EDC0";
}

.psi-microphone-5:before {
    content: "\EDC1";
}

.psi-microphone-6:before {
    content: "\EDC2";
}

.psi-microphone-7:before {
    content: "\EDC3";
}

.psi-microscope:before {
    content: "\EDC4";
}

.psi-milk-bottle:before {
    content: "\EDC5";
}

.psi-milk-bottle-2:before {
    content: "\EDC6";
}

.psi-min:before {
    content: "\EDC7";
}

.psi-mine:before {
    content: "\EDC8";
}

.psi-minimize:before {
    content: "\EDC9";
}

.psi-minimize-2:before {
    content: "\EDCA";
}

.psi-minimize-3:before {
    content: "\EDCB";
}

.psi-minimize-maximize-close-window:before {
    content: "\EDCC";
}

.psi-minimize-window:before {
    content: "\EDCD";
}

.psi-mirror:before {
    content: "\EDCE";
}

.psi-mixer:before {
    content: "\EDCF";
}

.psi-mixx:before {
    content: "\EDD0";
}

.psi-money:before {
    content: "\EDD1";
}

.psi-money-2:before {
    content: "\EDD2";
}

.psi-money-bag:before {
    content: "\EDD3";
}

.psi-money-smiley:before {
    content: "\EDD4";
}

.psi-monitor:before {
    content: "\EDD5";
}

.psi-monitor-2:before {
    content: "\EDD6";
}

.psi-monitor-3:before {
    content: "\EDD7";
}

.psi-monitor-4:before {
    content: "\EDD8";
}

.psi-monitor-5:before {
    content: "\EDD9";
}

.psi-monitor-analytics:before {
    content: "\EDDA";
}

.psi-monitor-laptop:before {
    content: "\EDDB";
}

.psi-monitor-phone:before {
    content: "\EDDC";
}

.psi-monitor-tablet:before {
    content: "\EDDD";
}

.psi-monitor-vertical:before {
    content: "\EDDE";
}

.psi-monitoring:before {
    content: "\EDDF";
}

.psi-monkey:before {
    content: "\EDE0";
}

.psi-monster:before {
    content: "\EDE1";
}

.psi-morocco:before {
    content: "\EDE2";
}

.psi-motorcycle:before {
    content: "\EDE3";
}

.psi-mouse:before {
    content: "\EDE4";
}

.psi-mouse-2:before {
    content: "\EDE5";
}

.psi-mouse-3:before {
    content: "\EDE6";
}

.psi-mouse-4:before {
    content: "\EDE7";
}

.psi-mouse-pointer:before {
    content: "\EDE8";
}

.psi-moustache-smiley:before {
    content: "\EDE9";
}

.psi-movie:before {
    content: "\EDEA";
}

.psi-movie-ticket:before {
    content: "\EDEB";
}

.psi-mp3-file:before {
    content: "\EDEC";
}

.psi-museum:before {
    content: "\EDED";
}

.psi-mushroom:before {
    content: "\EDEE";
}

.psi-music-note:before {
    content: "\EDEF";
}

.psi-music-note-2:before {
    content: "\EDF0";
}

.psi-music-note-3:before {
    content: "\EDF1";
}

.psi-music-note-4:before {
    content: "\EDF2";
}

.psi-music-player:before {
    content: "\EDF3";
}

.psi-mustache:before {
    content: "\EDF4";
}

.psi-mustache-2:before {
    content: "\EDF5";
}

.psi-mustache-3:before {
    content: "\EDF6";
}

.psi-mustache-4:before {
    content: "\EDF7";
}

.psi-mustache-5:before {
    content: "\EDF8";
}

.psi-mustache-6:before {
    content: "\EDF9";
}

.psi-mustache-7:before {
    content: "\EDFA";
}

.psi-mustache-8:before {
    content: "\EDFB";
}

.psi-mute:before {
    content: "\EDFC";
}

.psi-myspace:before {
    content: "\EDFD";
}

.psi-navigate-end:before {
    content: "\EDFE";
}

.psi-navigate-start:before {
    content: "\EDFF";
}

.psi-navigation-left-window:before {
    content: "\EE00";
}

.psi-navigation-right-window:before {
    content: "\EE01";
}

.psi-nepal:before {
    content: "\EE02";
}

.psi-netscape:before {
    content: "\EE03";
}

.psi-network:before {
    content: "\EE04";
}

.psi-network-window:before {
    content: "\EE05";
}

.psi-neutron:before {
    content: "\EE06";
}

.psi-new-mail:before {
    content: "\EE07";
}

.psi-new-tab:before {
    content: "\EE08";
}

.psi-newspaper:before {
    content: "\EE09";
}

.psi-newspaper-2:before {
    content: "\EE0A";
}

.psi-newsvine:before {
    content: "\EE0B";
}

.psi-next:before {
    content: "\EE0C";
}

.psi-next-media:before {
    content: "\EE0D";
}

.psi-next-media-2:before {
    content: "\EE0E";
}

.psi-next-music:before {
    content: "\EE0F";
}

.psi-no-battery:before {
    content: "\EE10";
}

.psi-no-drop:before {
    content: "\EE11";
}

.psi-no-flash:before {
    content: "\EE12";
}

.psi-no-smoking:before {
    content: "\EE13";
}

.psi-normal-text:before {
    content: "\EE14";
}

.psi-nose:before {
    content: "\EE15";
}

.psi-note:before {
    content: "\EE16";
}

.psi-notepad:before {
    content: "\EE17";
}

.psi-notepad-2:before {
    content: "\EE18";
}

.psi-nuclear:before {
    content: "\EE19";
}

.psi-numbering-list:before {
    content: "\EE1A";
}

.psi-nurse:before {
    content: "\EE1B";
}

.psi-office:before {
    content: "\EE1C";
}

.psi-office-lamp:before {
    content: "\EE1D";
}

.psi-oil:before {
    content: "\EE1E";
}

.psi-old-camera:before {
    content: "\EE1F";
}

.psi-old-cassette:before {
    content: "\EE20";
}

.psi-old-clock:before {
    content: "\EE21";
}

.psi-old-radio:before {
    content: "\EE22";
}

.psi-old-sticky:before {
    content: "\EE23";
}

.psi-old-sticky-2:before {
    content: "\EE24";
}

.psi-old-telephone:before {
    content: "\EE25";
}

.psi-old-tv:before {
    content: "\EE26";
}

.psi-on-air:before {
    content: "\EE27";
}

.psi-on-off:before {
    content: "\EE28";
}

.psi-on-off-2:before {
    content: "\EE29";
}

.psi-on-off-3:before {
    content: "\EE2A";
}

.psi-one-finger:before {
    content: "\EE2B";
}

.psi-one-finger-touch:before {
    content: "\EE2C";
}

.psi-one-window:before {
    content: "\EE2D";
}

.psi-open-banana:before {
    content: "\EE2E";
}

.psi-open-book:before {
    content: "\EE2F";
}

.psi-opera:before {
    content: "\EE30";
}

.psi-opera-house:before {
    content: "\EE31";
}

.psi-optimization:before {
    content: "\EE32";
}

.psi-orientation:before {
    content: "\EE33";
}

.psi-orientation-2:before {
    content: "\EE34";
}

.psi-orientation-3:before {
    content: "\EE35";
}

.psi-orkut:before {
    content: "\EE36";
}

.psi-ornament:before {
    content: "\EE37";
}

.psi-overtime:before {
    content: "\EE38";
}

.psi-overtime-2:before {
    content: "\EE39";
}

.psi-owl:before {
    content: "\EE3A";
}

.psi-pac-man:before {
    content: "\EE3B";
}

.psi-paint-brush:before {
    content: "\EE3C";
}

.psi-paint-bucket:before {
    content: "\EE3D";
}

.psi-paintbrush:before {
    content: "\EE3E";
}

.psi-palette:before {
    content: "\EE3F";
}

.psi-palm-tree:before {
    content: "\EE40";
}

.psi-panda:before {
    content: "\EE41";
}

.psi-panorama:before {
    content: "\EE42";
}

.psi-pantheon:before {
    content: "\EE43";
}

.psi-pantone:before {
    content: "\EE44";
}

.psi-pants:before {
    content: "\EE45";
}

.psi-paper:before {
    content: "\EE46";
}

.psi-paper-plane:before {
    content: "\EE47";
}

.psi-paperclip:before {
    content: "\EE48";
}

.psi-parasailing:before {
    content: "\EE49";
}

.psi-parrot:before {
    content: "\EE4A";
}

.psi-password:before {
    content: "\EE4B";
}

.psi-password-field:before {
    content: "\EE4C";
}

.psi-password-shopping:before {
    content: "\EE4D";
}

.psi-password-shopping-2:before {
    content: "\EE4E";
}

.psi-pause:before {
    content: "\EE4F";
}

.psi-pause-2:before {
    content: "\EE50";
}

.psi-paw:before {
    content: "\EE51";
}

.psi-pawn:before {
    content: "\EE52";
}

.psi-paypal:before {
    content: "\EE53";
}

.psi-pen:before {
    content: "\EE54";
}

.psi-pen-2:before {
    content: "\EE55";
}

.psi-pen-3:before {
    content: "\EE56";
}

.psi-pen-4:before {
    content: "\EE57";
}

.psi-pen-5:before {
    content: "\EE58";
}

.psi-pen-6:before {
    content: "\EE59";
}

.psi-pencil:before {
    content: "\EE5A";
}

.psi-pencil-ruler:before {
    content: "\EE5B";
}

.psi-penguin:before {
    content: "\EE5C";
}

.psi-pentagon:before {
    content: "\EE5D";
}

.psi-people-on-cloud:before {
    content: "\EE5E";
}

.psi-pepper:before {
    content: "\EE5F";
}

.psi-pepper-with-fire:before {
    content: "\EE60";
}

.psi-petrol:before {
    content: "\EE61";
}

.psi-petronas-tower:before {
    content: "\EE62";
}

.psi-philippines:before {
    content: "\EE63";
}

.psi-phone:before {
    content: "\EE64";
}

.psi-phone-2:before {
    content: "\EE65";
}

.psi-phone-3:before {
    content: "\EE66";
}

.psi-phone-3g:before {
    content: "\EE67";
}

.psi-phone-4g:before {
    content: "\EE68";
}

.psi-phone-simcard:before {
    content: "\EE69";
}

.psi-phone-sms:before {
    content: "\EE6A";
}

.psi-phone-wifi:before {
    content: "\EE6B";
}

.psi-photo:before {
    content: "\EE6C";
}

.psi-photo-2:before {
    content: "\EE6D";
}

.psi-photo-3:before {
    content: "\EE6E";
}

.psi-photo-album:before {
    content: "\EE6F";
}

.psi-photo-album-2:before {
    content: "\EE70";
}

.psi-photo-album-3:before {
    content: "\EE71";
}

.psi-photos:before {
    content: "\EE72";
}

.psi-physics:before {
    content: "\EE73";
}

.psi-pi:before {
    content: "\EE74";
}

.psi-piano:before {
    content: "\EE75";
}

.psi-picasa:before {
    content: "\EE76";
}

.psi-pie-chart:before {
    content: "\EE77";
}

.psi-pie-chart-2:before {
    content: "\EE78";
}

.psi-pie-chart-3:before {
    content: "\EE79";
}

.psi-pilates:before {
    content: "\EE7A";
}

.psi-pilates-2:before {
    content: "\EE7B";
}

.psi-pilates-3:before {
    content: "\EE7C";
}

.psi-pilot:before {
    content: "\EE7D";
}

.psi-pinch:before {
    content: "\EE7E";
}

.psi-ping-pong:before {
    content: "\EE7F";
}

.psi-pinterest:before {
    content: "\EE80";
}

.psi-pipe:before {
    content: "\EE81";
}

.psi-pipette:before {
    content: "\EE82";
}

.psi-pisces:before {
    content: "\EE83";
}

.psi-pisces-2:before {
    content: "\EE84";
}

.psi-pizza:before {
    content: "\EE85";
}

.psi-pizza-slice:before {
    content: "\EE86";
}

.psi-plane:before {
    content: "\EE87";
}

.psi-plane-2:before {
    content: "\EE88";
}

.psi-plant:before {
    content: "\EE89";
}

.psi-plasmid:before {
    content: "\EE8A";
}

.psi-plaster:before {
    content: "\EE8B";
}

.psi-plastic-cup-phone:before {
    content: "\EE8C";
}

.psi-plastic-cup-phone-2:before {
    content: "\EE8D";
}

.psi-plate:before {
    content: "\EE8E";
}

.psi-plates:before {
    content: "\EE8F";
}

.psi-plaxo:before {
    content: "\EE90";
}

.psi-play-music:before {
    content: "\EE91";
}

.psi-plug-in:before {
    content: "\EE92";
}

.psi-plug-in-2:before {
    content: "\EE93";
}

.psi-plurk:before {
    content: "\EE94";
}

.psi-pointer:before {
    content: "\EE95";
}

.psi-poland:before {
    content: "\EE96";
}

.psi-police:before {
    content: "\EE97";
}

.psi-police-man:before {
    content: "\EE98";
}

.psi-police-station:before {
    content: "\EE99";
}

.psi-police-woman:before {
    content: "\EE9A";
}

.psi-polo-shirt:before {
    content: "\EE9B";
}

.psi-portrait:before {
    content: "\EE9C";
}

.psi-portugal:before {
    content: "\EE9D";
}

.psi-post-mail:before {
    content: "\EE9E";
}

.psi-post-mail-2:before {
    content: "\EE9F";
}

.psi-post-office:before {
    content: "\EEA0";
}

.psi-post-sign:before {
    content: "\EEA1";
}

.psi-post-sign-2-ways:before {
    content: "\EEA2";
}

.psi-posterous:before {
    content: "\EEA3";
}

.psi-pound:before {
    content: "\EEA4";
}

.psi-pound-sign:before {
    content: "\EEA5";
}

.psi-pound-sign-2:before {
    content: "\EEA6";
}

.psi-power:before {
    content: "\EEA7";
}

.psi-power-2:before {
    content: "\EEA8";
}

.psi-power-3:before {
    content: "\EEA9";
}

.psi-power-cable:before {
    content: "\EEAA";
}

.psi-power-station:before {
    content: "\EEAB";
}

.psi-prater:before {
    content: "\EEAC";
}

.psi-present:before {
    content: "\EEAD";
}

.psi-presents:before {
    content: "\EEAE";
}

.psi-press:before {
    content: "\EEAF";
}

.psi-preview:before {
    content: "\EEB0";
}

.psi-previous:before {
    content: "\EEB1";
}

.psi-pricing:before {
    content: "\EEB2";
}

.psi-printer:before {
    content: "\EEB3";
}

.psi-professor:before {
    content: "\EEB4";
}

.psi-profile:before {
    content: "\EEB5";
}

.psi-project:before {
    content: "\EEB6";
}

.psi-projector:before {
    content: "\EEB7";
}

.psi-projector-2:before {
    content: "\EEB8";
}

.psi-pulse:before {
    content: "\EEB9";
}

.psi-pumpkin:before {
    content: "\EEBA";
}

.psi-punk:before {
    content: "\EEBB";
}

.psi-punker:before {
    content: "\EEBC";
}

.psi-puzzle:before {
    content: "\EEBD";
}

.psi-pyramids:before {
    content: "\EEBE";
}

.psi-qik:before {
    content: "\EEBF";
}

.psi-qr-code:before {
    content: "\EEC0";
}

.psi-queen:before {
    content: "\EEC1";
}

.psi-queen-2:before {
    content: "\EEC2";
}

.psi-question:before {
    content: "\EEC3";
}

.psi-quill:before {
    content: "\EEC4";
}

.psi-quill-2:before {
    content: "\EEC5";
}

.psi-quill-3:before {
    content: "\EEC6";
}

.psi-quotes:before {
    content: "\EEC7";
}

.psi-quotes-2:before {
    content: "\EEC8";
}

.psi-radio:before {
    content: "\EEC9";
}

.psi-radioactive:before {
    content: "\EECA";
}

.psi-rafting:before {
    content: "\EECB";
}

.psi-rain-drop:before {
    content: "\EECC";
}

.psi-rainbow:before {
    content: "\EECD";
}

.psi-rainbow-2:before {
    content: "\EECE";
}

.psi-ram:before {
    content: "\EECF";
}

.psi-razor-blade:before {
    content: "\EED0";
}

.psi-receipt:before {
    content: "\EED1";
}

.psi-receipt-2:before {
    content: "\EED2";
}

.psi-receipt-3:before {
    content: "\EED3";
}

.psi-receipt-4:before {
    content: "\EED4";
}

.psi-record:before {
    content: "\EED5";
}

.psi-record-3:before {
    content: "\EED6";
}

.psi-record-music:before {
    content: "\EED7";
}

.psi-record-music-2:before {
    content: "\EED8";
}

.psi-recycling:before {
    content: "\EED9";
}

.psi-recycling-2:before {
    content: "\EEDA";
}

.psi-reddit:before {
    content: "\EEDB";
}

.psi-redhat:before {
    content: "\EEDC";
}

.psi-redirect:before {
    content: "\EEDD";
}

.psi-redo:before {
    content: "\EEDE";
}

.psi-reel:before {
    content: "\EEDF";
}

.psi-refinery:before {
    content: "\EEE0";
}

.psi-refresh:before {
    content: "\EEE1";
}

.psi-refresh-window:before {
    content: "\EEE2";
}

.psi-reload:before {
    content: "\EEE3";
}

.psi-reload-2:before {
    content: "\EEE4";
}

.psi-reload-3:before {
    content: "\EEE5";
}

.psi-remote-control:before {
    content: "\EEE6";
}

.psi-remote-control-2:before {
    content: "\EEE7";
}

.psi-remove:before {
    content: "\EEE8";
}

.psi-remove-bag:before {
    content: "\EEE9";
}

.psi-remove-basket:before {
    content: "\EEEA";
}

.psi-remove-cart:before {
    content: "\EEEB";
}

.psi-remove-user:before {
    content: "\EEEC";
}

.psi-remove-window:before {
    content: "\EEED";
}

.psi-rename:before {
    content: "\EEEE";
}

.psi-repair:before {
    content: "\EEEF";
}

.psi-repeat:before {
    content: "\EEF0";
}

.psi-repeat-2:before {
    content: "\EEF1";
}

.psi-repeat-3:before {
    content: "\EEF2";
}

.psi-repeat-4:before {
    content: "\EEF3";
}

.psi-repeat-5:before {
    content: "\EEF4";
}

.psi-repeat-6:before {
    content: "\EEF5";
}

.psi-repeat-7:before {
    content: "\EEF6";
}

.psi-reset:before {
    content: "\EEF7";
}

.psi-resize:before {
    content: "\EEF8";
}

.psi-restore-window:before {
    content: "\EEF9";
}

.psi-retouching:before {
    content: "\EEFA";
}

.psi-retro:before {
    content: "\EEFB";
}

.psi-retro-camera:before {
    content: "\EEFC";
}

.psi-retweet:before {
    content: "\EEFD";
}

.psi-reverbnation:before {
    content: "\EEFE";
}

.psi-rewind:before {
    content: "\EEFF";
}

.psi-rgb:before {
    content: "\EF00";
}

.psi-ribbon:before {
    content: "\EF01";
}

.psi-ribbon-2:before {
    content: "\EF02";
}

.psi-ribbon-3:before {
    content: "\EF03";
}

.psi-right:before {
    content: "\EF04";
}

.psi-right-2:before {
    content: "\EF05";
}

.psi-right-3:before {
    content: "\EF06";
}

.psi-right-4:before {
    content: "\EF07";
}

.psi-right-to-left:before {
    content: "\EF08";
}

.psi-road:before {
    content: "\EF09";
}

.psi-road-2:before {
    content: "\EF0A";
}

.psi-road-3:before {
    content: "\EF0B";
}

.psi-robot:before {
    content: "\EF0C";
}

.psi-robot-2:before {
    content: "\EF0D";
}

.psi-rock-and-roll:before {
    content: "\EF0E";
}

.psi-rocket:before {
    content: "\EF0F";
}

.psi-roller:before {
    content: "\EF10";
}

.psi-roof:before {
    content: "\EF11";
}

.psi-rook:before {
    content: "\EF12";
}

.psi-rotate-gesture:before {
    content: "\EF13";
}

.psi-rotate-gesture-2:before {
    content: "\EF14";
}

.psi-rotate-gesture-3:before {
    content: "\EF15";
}

.psi-rotation:before {
    content: "\EF16";
}

.psi-rotation-360:before {
    content: "\EF17";
}

.psi-router:before {
    content: "\EF18";
}

.psi-router-2:before {
    content: "\EF19";
}

.psi-rss:before {
    content: "\EF1A";
}

.psi-ruler:before {
    content: "\EF1B";
}

.psi-ruler-2:before {
    content: "\EF1C";
}

.psi-running:before {
    content: "\EF1D";
}

.psi-running-shoes:before {
    content: "\EF1E";
}

.psi-safari:before {
    content: "\EF1F";
}

.psi-safe-box:before {
    content: "\EF20";
}

.psi-safe-box-2:before {
    content: "\EF21";
}

.psi-safety-pin-close:before {
    content: "\EF22";
}

.psi-safety-pin-open:before {
    content: "\EF23";
}

.psi-sagittarius:before {
    content: "\EF24";
}

.psi-sagittarius-2:before {
    content: "\EF25";
}

.psi-sailing-ship:before {
    content: "\EF26";
}

.psi-sandwatch:before {
    content: "\EF27";
}

.psi-sandwatch-2:before {
    content: "\EF28";
}

.psi-santa-claus:before {
    content: "\EF29";
}

.psi-santa-claus-2:before {
    content: "\EF2A";
}

.psi-santa-on-sled:before {
    content: "\EF2B";
}

.psi-satellite:before {
    content: "\EF2C";
}

.psi-satellite-2:before {
    content: "\EF2D";
}

.psi-save:before {
    content: "\EF2E";
}

.psi-save-window:before {
    content: "\EF2F";
}

.psi-saw:before {
    content: "\EF30";
}

.psi-saxophone:before {
    content: "\EF31";
}

.psi-scale:before {
    content: "\EF32";
}

.psi-scarf:before {
    content: "\EF33";
}

.psi-scissor:before {
    content: "\EF34";
}

.psi-scooter:before {
    content: "\EF35";
}

.psi-scooter-front:before {
    content: "\EF36";
}

.psi-scorpio:before {
    content: "\EF37";
}

.psi-scorpio-2:before {
    content: "\EF38";
}

.psi-scotland:before {
    content: "\EF39";
}

.psi-screwdriver:before {
    content: "\EF3A";
}

.psi-scroll:before {
    content: "\EF3B";
}

.psi-scroll-fast:before {
    content: "\EF3C";
}

.psi-scroller:before {
    content: "\EF3D";
}

.psi-scroller-2:before {
    content: "\EF3E";
}

.psi-sea-dog:before {
    content: "\EF3F";
}

.psi-search-on-cloud:before {
    content: "\EF40";
}

.psi-search-people:before {
    content: "\EF41";
}

.psi-seconds:before {
    content: "\EF42";
}

.psi-seconds-2:before {
    content: "\EF43";
}

.psi-security-block:before {
    content: "\EF44";
}

.psi-security-bug:before {
    content: "\EF45";
}

.psi-security-camera:before {
    content: "\EF46";
}

.psi-security-check:before {
    content: "\EF47";
}

.psi-security-remove:before {
    content: "\EF48";
}

.psi-security-settings:before {
    content: "\EF49";
}

.psi-security-smiley:before {
    content: "\EF4A";
}

.psi-seed:before {
    content: "\EF4B";
}

.psi-selfie:before {
    content: "\EF4C";
}

.psi-serbia:before {
    content: "\EF4D";
}

.psi-server:before {
    content: "\EF4E";
}

.psi-server-2:before {
    content: "\EF4F";
}

.psi-servers:before {
    content: "\EF50";
}

.psi-settings-window:before {
    content: "\EF51";
}

.psi-sewing-machine:before {
    content: "\EF52";
}

.psi-share:before {
    content: "\EF53";
}

.psi-share-on-cloud:before {
    content: "\EF54";
}

.psi-share-window:before {
    content: "\EF55";
}

.psi-sharethis:before {
    content: "\EF56";
}

.psi-shark:before {
    content: "\EF57";
}

.psi-sheep:before {
    content: "\EF58";
}

.psi-sheriff-badge:before {
    content: "\EF59";
}

.psi-shield:before {
    content: "\EF5A";
}

.psi-ship:before {
    content: "\EF5B";
}

.psi-ship-2:before {
    content: "\EF5C";
}

.psi-shirt:before {
    content: "\EF5D";
}

.psi-shoes:before {
    content: "\EF5E";
}

.psi-shoes-2:before {
    content: "\EF5F";
}

.psi-shoes-3:before {
    content: "\EF60";
}

.psi-shop:before {
    content: "\EF61";
}

.psi-shop-2:before {
    content: "\EF62";
}

.psi-shop-3:before {
    content: "\EF63";
}

.psi-shop-4:before {
    content: "\EF64";
}

.psi-shopping-bag:before {
    content: "\EF65";
}

.psi-shopping-basket:before {
    content: "\EF66";
}

.psi-shopping-cart:before {
    content: "\EF67";
}

.psi-short-pants:before {
    content: "\EF68";
}

.psi-shoutwire:before {
    content: "\EF69";
}

.psi-shovel:before {
    content: "\EF6A";
}

.psi-shuffle:before {
    content: "\EF6B";
}

.psi-shuffle-2:before {
    content: "\EF6C";
}

.psi-shuffle-3:before {
    content: "\EF6D";
}

.psi-shuffle-4:before {
    content: "\EF6E";
}

.psi-shutter:before {
    content: "\EF6F";
}

.psi-sidebar-window:before {
    content: "\EF70";
}

.psi-signal:before {
    content: "\EF71";
}

.psi-singapore:before {
    content: "\EF72";
}

.psi-skate-shoes:before {
    content: "\EF73";
}

.psi-skateboard:before {
    content: "\EF74";
}

.psi-skateboard-2:before {
    content: "\EF75";
}

.psi-skeleton:before {
    content: "\EF76";
}

.psi-ski:before {
    content: "\EF77";
}

.psi-skirt:before {
    content: "\EF78";
}

.psi-skrill:before {
    content: "\EF79";
}

.psi-skull:before {
    content: "\EF7A";
}

.psi-skydiving:before {
    content: "\EF7B";
}

.psi-skype:before {
    content: "\EF7C";
}

.psi-sled:before {
    content: "\EF7D";
}

.psi-sled-with-gifts:before {
    content: "\EF7E";
}

.psi-sleeping:before {
    content: "\EF7F";
}

.psi-sleet:before {
    content: "\EF80";
}

.psi-slippers:before {
    content: "\EF81";
}

.psi-smart:before {
    content: "\EF82";
}

.psi-smartphone:before {
    content: "\EF83";
}

.psi-smartphone-2:before {
    content: "\EF84";
}

.psi-smartphone-3:before {
    content: "\EF85";
}

.psi-smartphone-4:before {
    content: "\EF86";
}

.psi-smartphone-secure:before {
    content: "\EF87";
}

.psi-smile:before {
    content: "\EF88";
}

.psi-smoking-area:before {
    content: "\EF89";
}

.psi-smoking-pipe:before {
    content: "\EF8A";
}

.psi-snake:before {
    content: "\EF8B";
}

.psi-snorkel:before {
    content: "\EF8C";
}

.psi-snow:before {
    content: "\EF8D";
}

.psi-snow-2:before {
    content: "\EF8E";
}

.psi-snow-dome:before {
    content: "\EF8F";
}

.psi-snow-storm:before {
    content: "\EF90";
}

.psi-snowflake:before {
    content: "\EF91";
}

.psi-snowflake-2:before {
    content: "\EF92";
}

.psi-snowflake-3:before {
    content: "\EF93";
}

.psi-snowflake-4:before {
    content: "\EF94";
}

.psi-snowman:before {
    content: "\EF95";
}

.psi-soccer-ball:before {
    content: "\EF96";
}

.psi-soccer-shoes:before {
    content: "\EF97";
}

.psi-socks:before {
    content: "\EF98";
}

.psi-solar:before {
    content: "\EF99";
}

.psi-sound:before {
    content: "\EF9A";
}

.psi-sound-wave:before {
    content: "\EF9B";
}

.psi-soundcloud:before {
    content: "\EF9C";
}

.psi-soup:before {
    content: "\EF9D";
}

.psi-south-africa:before {
    content: "\EF9E";
}

.psi-space-needle:before {
    content: "\EF9F";
}

.psi-spain:before {
    content: "\EFA0";
}

.psi-spam-mail:before {
    content: "\EFA1";
}

.psi-speak:before {
    content: "\EFA2";
}

.psi-speak-2:before {
    content: "\EFA3";
}

.psi-speaker:before {
    content: "\EFA4";
}

.psi-speaker-2:before {
    content: "\EFA5";
}

.psi-speech-bubble:before {
    content: "\EFA6";
}

.psi-speech-bubble-10:before {
    content: "\EFA7";
}

.psi-speech-bubble-11:before {
    content: "\EFA8";
}

.psi-speech-bubble-12:before {
    content: "\EFA9";
}

.psi-speech-bubble-13:before {
    content: "\EFAA";
}

.psi-speech-bubble-2:before {
    content: "\EFAB";
}

.psi-speech-bubble-3:before {
    content: "\EFAC";
}

.psi-speech-bubble-4:before {
    content: "\EFAD";
}

.psi-speech-bubble-5:before {
    content: "\EFAE";
}

.psi-speech-bubble-6:before {
    content: "\EFAF";
}

.psi-speech-bubble-7:before {
    content: "\EFB0";
}

.psi-speech-bubble-8:before {
    content: "\EFB1";
}

.psi-speech-bubble-9:before {
    content: "\EFB2";
}

.psi-speech-bubble-asking:before {
    content: "\EFB3";
}

.psi-speech-bubble-comic:before {
    content: "\EFB4";
}

.psi-speech-bubble-comic-2:before {
    content: "\EFB5";
}

.psi-speech-bubble-comic-3:before {
    content: "\EFB6";
}

.psi-speech-bubble-comic-4:before {
    content: "\EFB7";
}

.psi-speech-bubble-dialog:before {
    content: "\EFB8";
}

.psi-speech-bubbles:before {
    content: "\EFB9";
}

.psi-spell-check:before {
    content: "\EFBA";
}

.psi-spell-check-abc:before {
    content: "\EFBB";
}

.psi-spermium:before {
    content: "\EFBC";
}

.psi-spider:before {
    content: "\EFBD";
}

.psi-spider-2:before {
    content: "\EFBE";
}

.psi-spiderweb:before {
    content: "\EFBF";
}

.psi-split-four-windows:before {
    content: "\EFC0";
}

.psi-split-horizontal:before {
    content: "\EFC1";
}

.psi-split-horizontal-2:before {
    content: "\EFC2";
}

.psi-split-vertical:before {
    content: "\EFC3";
}

.psi-split-vertical-2:before {
    content: "\EFC4";
}

.psi-split-window:before {
    content: "\EFC5";
}

.psi-spoon:before {
    content: "\EFC6";
}

.psi-sport-mode:before {
    content: "\EFC7";
}

.psi-sports-clothing:before {
    content: "\EFC8";
}

.psi-sports-clothing-2:before {
    content: "\EFC9";
}

.psi-sports-shirt:before {
    content: "\EFCA";
}

.psi-spot:before {
    content: "\EFCB";
}

.psi-spray:before {
    content: "\EFCC";
}

.psi-spread:before {
    content: "\EFCD";
}

.psi-spring:before {
    content: "\EFCE";
}

.psi-spurl:before {
    content: "\EFCF";
}

.psi-spy:before {
    content: "\EFD0";
}

.psi-squirrel:before {
    content: "\EFD1";
}

.psi-ssl:before {
    content: "\EFD2";
}

.psi-st-basilscathedral:before {
    content: "\EFD3";
}

.psi-st-paulscathedral:before {
    content: "\EFD4";
}

.psi-stamp:before {
    content: "\EFD5";
}

.psi-stamp-2:before {
    content: "\EFD6";
}

.psi-stapler:before {
    content: "\EFD7";
}

.psi-star:before {
    content: "\EFD8";
}

.psi-star-2:before {
    content: "\EFD9";
}

.psi-star-track:before {
    content: "\EFDA";
}

.psi-starfish:before {
    content: "\EFDB";
}

.psi-start:before {
    content: "\EFDC";
}

.psi-start-2:before {
    content: "\EFDD";
}

.psi-start-3:before {
    content: "\EFDE";
}

.psi-start-ways:before {
    content: "\EFDF";
}

.psi-statistic:before {
    content: "\EFE0";
}

.psi-stethoscope:before {
    content: "\EFE1";
}

.psi-stop:before {
    content: "\EFE2";
}

.psi-stop-2:before {
    content: "\EFE3";
}

.psi-stop-music:before {
    content: "\EFE4";
}

.psi-stopwatch:before {
    content: "\EFE5";
}

.psi-stopwatch-2:before {
    content: "\EFE6";
}

.psi-storm:before {
    content: "\EFE7";
}

.psi-street-view:before {
    content: "\EFE8";
}

.psi-street-view-2:before {
    content: "\EFE9";
}

.psi-strikethrough-text:before {
    content: "\EFEA";
}

.psi-stroller:before {
    content: "\EFEB";
}

.psi-structure:before {
    content: "\EFEC";
}

.psi-student-female:before {
    content: "\EFED";
}

.psi-student-hat:before {
    content: "\EFEE";
}

.psi-student-hat-2:before {
    content: "\EFEF";
}

.psi-student-male:before {
    content: "\EFF0";
}

.psi-student-male-female:before {
    content: "\EFF1";
}

.psi-students:before {
    content: "\EFF2";
}

.psi-studio-flash:before {
    content: "\EFF3";
}

.psi-studio-lightbox:before {
    content: "\EFF4";
}

.psi-stumbleupon:before {
    content: "\EFF5";
}

.psi-suit:before {
    content: "\EFF6";
}

.psi-suitcase:before {
    content: "\EFF7";
}

.psi-sum:before {
    content: "\EFF8";
}

.psi-sum-2:before {
    content: "\EFF9";
}

.psi-summer:before {
    content: "\EFFA";
}

.psi-sun:before {
    content: "\EFFB";
}

.psi-sun-cloudy-rain:before {
    content: "\EFFC";
}

.psi-sunglasses:before {
    content: "\EFFD";
}

.psi-sunglasses-2:before {
    content: "\EFFE";
}

.psi-sunglasses-3:before {
    content: "\EFFF";
}

.psi-sunglasses-smiley:before {
    content: "\F000";
}

.psi-sunglasses-smiley-2:before {
    content: "\F001";
}

.psi-sunglasses-w:before {
    content: "\F002";
}

.psi-sunglasses-w-2:before {
    content: "\F003";
}

.psi-sunglasses-w-3:before {
    content: "\F004";
}

.psi-sunrise:before {
    content: "\F005";
}

.psi-sunset:before {
    content: "\F006";
}

.psi-superman:before {
    content: "\F007";
}

.psi-support:before {
    content: "\F008";
}

.psi-surprise:before {
    content: "\F009";
}

.psi-sushi:before {
    content: "\F00A";
}

.psi-sweden:before {
    content: "\F00B";
}

.psi-swimming:before {
    content: "\F00C";
}

.psi-swimming-short:before {
    content: "\F00D";
}

.psi-swimwear:before {
    content: "\F00E";
}

.psi-switch:before {
    content: "\F00F";
}

.psi-switzerland:before {
    content: "\F010";
}

.psi-sync:before {
    content: "\F011";
}

.psi-sync-cloud:before {
    content: "\F012";
}

.psi-synchronize:before {
    content: "\F013";
}

.psi-synchronize-2:before {
    content: "\F014";
}

.psi-t-shirt:before {
    content: "\F015";
}

.psi-tablet:before {
    content: "\F016";
}

.psi-tablet-2:before {
    content: "\F017";
}

.psi-tablet-3:before {
    content: "\F018";
}

.psi-tablet-orientation:before {
    content: "\F019";
}

.psi-tablet-phone:before {
    content: "\F01A";
}

.psi-tablet-secure:before {
    content: "\F01B";
}

.psi-tablet-vertical:before {
    content: "\F01C";
}

.psi-tactic:before {
    content: "\F01D";
}

.psi-tag:before {
    content: "\F01E";
}

.psi-tag-2:before {
    content: "\F01F";
}

.psi-tag-3:before {
    content: "\F020";
}

.psi-tag-4:before {
    content: "\F021";
}

.psi-tag-5:before {
    content: "\F022";
}

.psi-taj-mahal:before {
    content: "\F023";
}

.psi-talk-man:before {
    content: "\F024";
}

.psi-tap:before {
    content: "\F025";
}

.psi-target:before {
    content: "\F026";
}

.psi-target-market:before {
    content: "\F027";
}

.psi-taurus:before {
    content: "\F028";
}

.psi-taurus-2:before {
    content: "\F029";
}

.psi-taxi:before {
    content: "\F02A";
}

.psi-taxi-2:before {
    content: "\F02B";
}

.psi-taxi-sign:before {
    content: "\F02C";
}

.psi-tea-mug:before {
    content: "\F02D";
}

.psi-teacher:before {
    content: "\F02E";
}

.psi-teapot:before {
    content: "\F02F";
}

.psi-technorati:before {
    content: "\F030";
}

.psi-teddy-bear:before {
    content: "\F031";
}

.psi-telephone:before {
    content: "\F032";
}

.psi-telephone-2:before {
    content: "\F033";
}

.psi-telescope:before {
    content: "\F034";
}

.psi-temperature:before {
    content: "\F035";
}

.psi-temperature-2:before {
    content: "\F036";
}

.psi-temperature-3:before {
    content: "\F037";
}

.psi-temple:before {
    content: "\F038";
}

.psi-tennis:before {
    content: "\F039";
}

.psi-tennis-ball:before {
    content: "\F03A";
}

.psi-tent:before {
    content: "\F03B";
}

.psi-test-tube:before {
    content: "\F03C";
}

.psi-test-tube-2:before {
    content: "\F03D";
}

.psi-testimonal:before {
    content: "\F03E";
}

.psi-text-box:before {
    content: "\F03F";
}

.psi-text-effect:before {
    content: "\F040";
}

.psi-text-highlight-color:before {
    content: "\F041";
}

.psi-text-paragraph:before {
    content: "\F042";
}

.psi-thailand:before {
    content: "\F043";
}

.psi-the-white-house:before {
    content: "\F044";
}

.psi-this-side-up:before {
    content: "\F045";
}

.psi-thread:before {
    content: "\F046";
}

.psi-three-arrow-fork:before {
    content: "\F047";
}

.psi-three-fingers:before {
    content: "\F048";
}

.psi-three-fingers-drag:before {
    content: "\F049";
}

.psi-three-fingers-drag-2:before {
    content: "\F04A";
}

.psi-three-fingers-touch:before {
    content: "\F04B";
}

.psi-thumb:before {
    content: "\F04C";
}

.psi-thumbs-down-smiley:before {
    content: "\F04D";
}

.psi-thumbs-up-smiley:before {
    content: "\F04E";
}

.psi-thunder:before {
    content: "\F04F";
}

.psi-thunderstorm:before {
    content: "\F050";
}

.psi-ticket:before {
    content: "\F051";
}

.psi-tie:before {
    content: "\F052";
}

.psi-tie-2:before {
    content: "\F053";
}

.psi-tie-3:before {
    content: "\F054";
}

.psi-tie-4:before {
    content: "\F055";
}

.psi-tiger:before {
    content: "\F056";
}

.psi-time-backup:before {
    content: "\F057";
}

.psi-time-bomb:before {
    content: "\F058";
}

.psi-time-clock:before {
    content: "\F059";
}

.psi-time-fire:before {
    content: "\F05A";
}

.psi-time-machine:before {
    content: "\F05B";
}

.psi-time-window:before {
    content: "\F05C";
}

.psi-timer:before {
    content: "\F05D";
}

.psi-timer-2:before {
    content: "\F05E";
}

.psi-to-bottom:before {
    content: "\F05F";
}

.psi-to-bottom-2:before {
    content: "\F060";
}

.psi-to-left:before {
    content: "\F061";
}

.psi-to-right:before {
    content: "\F062";
}

.psi-to-top:before {
    content: "\F063";
}

.psi-to-top-2:before {
    content: "\F064";
}

.psi-token:before {
    content: "\F065";
}

.psi-tomato:before {
    content: "\F066";
}

.psi-tongue:before {
    content: "\F067";
}

.psi-tooth:before {
    content: "\F068";
}

.psi-tooth-2:before {
    content: "\F069";
}

.psi-top-to-bottom:before {
    content: "\F06A";
}

.psi-torch:before {
    content: "\F06B";
}

.psi-touch-window:before {
    content: "\F06C";
}

.psi-tower:before {
    content: "\F06D";
}

.psi-tower-2:before {
    content: "\F06E";
}

.psi-tower-bridge:before {
    content: "\F06F";
}

.psi-trace:before {
    content: "\F070";
}

.psi-tractor:before {
    content: "\F071";
}

.psi-traffic-light:before {
    content: "\F072";
}

.psi-traffic-light-2:before {
    content: "\F073";
}

.psi-train:before {
    content: "\F074";
}

.psi-train-2:before {
    content: "\F075";
}

.psi-tram:before {
    content: "\F076";
}

.psi-transform:before {
    content: "\F077";
}

.psi-transform-2:before {
    content: "\F078";
}

.psi-transform-3:before {
    content: "\F079";
}

.psi-transform-4:before {
    content: "\F07A";
}

.psi-trash:before {
    content: "\F07B";
}

.psi-trash-with-men:before {
    content: "\F07C";
}

.psi-tree:before {
    content: "\F07D";
}

.psi-tree-2:before {
    content: "\F07E";
}

.psi-tree-3:before {
    content: "\F07F";
}

.psi-tree-4:before {
    content: "\F080";
}

.psi-tree-5:before {
    content: "\F081";
}

.psi-trekking:before {
    content: "\F082";
}

.psi-triangle-arrow-down:before {
    content: "\F083";
}

.psi-triangle-arrow-left:before {
    content: "\F084";
}

.psi-triangle-arrow-right:before {
    content: "\F085";
}

.psi-triangle-arrow-up:before {
    content: "\F086";
}

.psi-tripod-2:before {
    content: "\F087";
}

.psi-tripod-and-video:before {
    content: "\F088";
}

.psi-tripod-with-camera:before {
    content: "\F089";
}

.psi-tripod-with-gopro:before {
    content: "\F08A";
}

.psi-trophy:before {
    content: "\F08B";
}

.psi-trophy-2:before {
    content: "\F08C";
}

.psi-truck:before {
    content: "\F08D";
}

.psi-trumpet:before {
    content: "\F08E";
}

.psi-tumblr:before {
    content: "\F08F";
}

.psi-turkey:before {
    content: "\F090";
}

.psi-turn-down:before {
    content: "\F091";
}

.psi-turn-down-2:before {
    content: "\F092";
}

.psi-turn-down-from-left:before {
    content: "\F093";
}

.psi-turn-down-from-right:before {
    content: "\F094";
}

.psi-turn-left:before {
    content: "\F095";
}

.psi-turn-left-3:before {
    content: "\F096";
}

.psi-turn-right:before {
    content: "\F097";
}

.psi-turn-right-3:before {
    content: "\F098";
}

.psi-turn-up:before {
    content: "\F099";
}

.psi-turn-up-2:before {
    content: "\F09A";
}

.psi-turtle:before {
    content: "\F09B";
}

.psi-tuxedo:before {
    content: "\F09C";
}

.psi-tv:before {
    content: "\F09D";
}

.psi-twister:before {
    content: "\F09E";
}

.psi-twitter:before {
    content: "\F09F";
}

.psi-twitter-2:before {
    content: "\F0A0";
}

.psi-two-fingers:before {
    content: "\F0A1";
}

.psi-two-fingers-drag:before {
    content: "\F0A2";
}

.psi-two-fingers-drag-2:before {
    content: "\F0A3";
}

.psi-two-fingers-scroll:before {
    content: "\F0A4";
}

.psi-two-fingers-touch:before {
    content: "\F0A5";
}

.psi-two-windows:before {
    content: "\F0A6";
}

.psi-type-pass:before {
    content: "\F0A7";
}

.psi-ukraine:before {
    content: "\F0A8";
}

.psi-umbrella:before {
    content: "\F0A9";
}

.psi-umbrella-2:before {
    content: "\F0AA";
}

.psi-umbrella-3:before {
    content: "\F0AB";
}

.psi-underline-text:before {
    content: "\F0AC";
}

.psi-undo:before {
    content: "\F0AD";
}

.psi-united-kingdom:before {
    content: "\F0AE";
}

.psi-united-states:before {
    content: "\F0AF";
}

.psi-university:before {
    content: "\F0B0";
}

.psi-university-2:before {
    content: "\F0B1";
}

.psi-unlike:before {
    content: "\F0B2";
}

.psi-unlike-2:before {
    content: "\F0B3";
}

.psi-unlock:before {
    content: "\F0B4";
}

.psi-unlock-2:before {
    content: "\F0B5";
}

.psi-unlock-3:before {
    content: "\F0B6";
}

.psi-up:before {
    content: "\F0B7";
}

.psi-up-2:before {
    content: "\F0B8";
}

.psi-up-3:before {
    content: "\F0B9";
}

.psi-up-4:before {
    content: "\F0BA";
}

.psi-up-down:before {
    content: "\F0BB";
}

.psi-up-down-3:before {
    content: "\F0BC";
}

.psi-upgrade:before {
    content: "\F0BD";
}

.psi-upload:before {
    content: "\F0BE";
}

.psi-upload-2:before {
    content: "\F0BF";
}

.psi-upload-to-cloud:before {
    content: "\F0C0";
}

.psi-upload-window:before {
    content: "\F0C1";
}

.psi-uppercase-text:before {
    content: "\F0C2";
}

.psi-upward:before {
    content: "\F0C3";
}

.psi-url-window:before {
    content: "\F0C4";
}

.psi-usb:before {
    content: "\F0C5";
}

.psi-usb-2:before {
    content: "\F0C6";
}

.psi-usb-cable:before {
    content: "\F0C7";
}

.psi-user:before {
    content: "\F0C8";
}

.psi-ustream:before {
    content: "\F0C9";
}

.psi-vase:before {
    content: "\F0CA";
}

.psi-vector:before {
    content: "\F0CB";
}

.psi-vector-2:before {
    content: "\F0CC";
}

.psi-vector-3:before {
    content: "\F0CD";
}

.psi-vector-4:before {
    content: "\F0CE";
}

.psi-vector-5:before {
    content: "\F0CF";
}

.psi-venn-diagram:before {
    content: "\F0D0";
}

.psi-vest:before {
    content: "\F0D1";
}

.psi-vest-2:before {
    content: "\F0D2";
}

.psi-viddler:before {
    content: "\F0D3";
}

.psi-video:before {
    content: "\F0D4";
}

.psi-video-2:before {
    content: "\F0D5";
}

.psi-video-3:before {
    content: "\F0D6";
}

.psi-video-4:before {
    content: "\F0D7";
}

.psi-video-5:before {
    content: "\F0D8";
}

.psi-video-6:before {
    content: "\F0D9";
}

.psi-video-game-controller:before {
    content: "\F0DA";
}

.psi-video-len:before {
    content: "\F0DB";
}

.psi-video-len-2:before {
    content: "\F0DC";
}

.psi-video-photographer:before {
    content: "\F0DD";
}

.psi-video-tripod:before {
    content: "\F0DE";
}

.psi-vietnam:before {
    content: "\F0DF";
}

.psi-view-height:before {
    content: "\F0E0";
}

.psi-list-view:before {
    content: "\F0E1";
}

.psi-view-width:before {
    content: "\F0E2";
}

.psi-vimeo:before {
    content: "\F0E3";
}

.psi-virgo:before {
    content: "\F0E4";
}

.psi-virgo-2:before {
    content: "\F0E5";
}

.psi-virus:before {
    content: "\F0E6";
}

.psi-virus-2:before {
    content: "\F0E7";
}

.psi-virus-3:before {
    content: "\F0E8";
}

.psi-visa:before {
    content: "\F0E9";
}

.psi-voice:before {
    content: "\F0EA";
}

.psi-voicemail:before {
    content: "\F0EB";
}

.psi-volleyball:before {
    content: "\F0EC";
}

.psi-volume-down:before {
    content: "\F0ED";
}

.psi-volume-up:before {
    content: "\F0EE";
}

.psi-vpn:before {
    content: "\F0EF";
}

.psi-wacom-tablet:before {
    content: "\F0F0";
}

.psi-waiter:before {
    content: "\F0F1";
}

.psi-walkie-talkie:before {
    content: "\F0F2";
}

.psi-wallet:before {
    content: "\F0F3";
}

.psi-wallet-2:before {
    content: "\F0F4";
}

.psi-wallet-3:before {
    content: "\F0F5";
}

.psi-warehouse:before {
    content: "\F0F6";
}

.psi-warning-window:before {
    content: "\F0F7";
}

.psi-watch:before {
    content: "\F0F8";
}

.psi-watch-2:before {
    content: "\F0F9";
}

.psi-watch-3:before {
    content: "\F0FA";
}

.psi-wave:before {
    content: "\F0FB";
}

.psi-wave-2:before {
    content: "\F0FC";
}

.psi-webcam:before {
    content: "\F0FD";
}

.psi-weight-lift:before {
    content: "\F0FE";
}

.psi-wheelbarrow:before {
    content: "\F0FF";
}

.psi-wheelchair:before {
    content: "\F100";
}

.psi-width-window:before {
    content: "\F101";
}

.psi-wifi:before {
    content: "\F102";
}

.psi-wifi-2:before {
    content: "\F103";
}

.psi-wifi-keyboard:before {
    content: "\F104";
}

.psi-wind-turbine:before {
    content: "\F105";
}

.psi-windmill:before {
    content: "\F106";
}

.psi-window:before {
    content: "\F107";
}

.psi-window-2:before {
    content: "\F108";
}

.psi-windows:before {
    content: "\F109";
}

.psi-windows-2:before {
    content: "\F10A";
}

.psi-windows-microsoft:before {
    content: "\F10B";
}

.psi-windsock:before {
    content: "\F10C";
}

.psi-windy:before {
    content: "\F10D";
}

.psi-wine-bottle:before {
    content: "\F10E";
}

.psi-wine-glass:before {
    content: "\F10F";
}

.psi-wink:before {
    content: "\F110";
}

.psi-winter:before {
    content: "\F111";
}

.psi-winter-2:before {
    content: "\F112";
}

.psi-wireless:before {
    content: "\F113";
}

.psi-witch:before {
    content: "\F114";
}

.psi-witch-hat:before {
    content: "\F115";
}

.psi-wizard:before {
    content: "\F116";
}

.psi-wolf:before {
    content: "\F117";
}

.psi-woman-man-sign:before {
    content: "\F118";
}

.psi-woman-sign:before {
    content: "\F119";
}

.psi-womans-underwear:before {
    content: "\F11A";
}

.psi-womans-underwear-2:before {
    content: "\F11B";
}

.psi-women:before {
    content: "\F11C";
}

.psi-wonder-woman:before {
    content: "\F11D";
}

.psi-wordpress:before {
    content: "\F11E";
}

.psi-worker:before {
    content: "\F11F";
}

.psi-worker-clothes:before {
    content: "\F120";
}

.psi-wrap-text:before {
    content: "\F121";
}

.psi-wreath:before {
    content: "\F122";
}

.psi-wrench:before {
    content: "\F123";
}

.psi-x-box:before {
    content: "\F124";
}

.psi-x-ray:before {
    content: "\F125";
}

.psi-xanga:before {
    content: "\F126";
}

.psi-xing:before {
    content: "\F127";
}

.psi-yacht:before {
    content: "\F128";
}

.psi-yahoo:before {
    content: "\F129";
}

.psi-yahoo-buzz:before {
    content: "\F12A";
}

.psi-yelp:before {
    content: "\F12B";
}

.psi-yes:before {
    content: "\F12C";
}

.psi-ying-yang:before {
    content: "\F12D";
}

.psi-youtube:before {
    content: "\F12E";
}

.psi-z-a:before {
    content: "\F12F";
}

.psi-zebra:before {
    content: "\F130";
}

.psi-zombie:before {
    content: "\F131";
}

.psi-zoom-gesture:before {
    content: "\F132";
}

.psi-zootool:before {
    content: "\F133";
}

.content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  

