body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.front-container1 {
  background-color: #edf1f6;
  color: #75868f;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
}


.justify-content-center {
  justify-content: center !important;
}

.text-center {
  text-align: center !important;
}

.text-cyan-400 {
  --bs-text-opacity: 1;
  color: rgba(61, 213, 243, var(--bs-text-opacity)) !important;
}
.text-teal-400 {
  --bs-text-opacity: 1;
  color: rgba(81, 184, 174, var(--bs-text-opacity)) !important;
}
.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(237, 241, 246, var(--bs-text-opacity)) !important;
}

.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(203, 208, 216, var(--bs-text-opacity)) !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}



.error-code-lg {
  font-size: 9rem !important;
  font-weight: 400;
  line-height: 1;
}

.error-code-md {
  font-size: 6rem !important;
  font-weight: 400;
  line-height: 1;
}

.error-code-sm {
  font-size: 3rem !important;
  font-weight: 400;
  line-height: 1;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
.centered-layout .content__boxed, .centered-layout .header__inner, .centered-layout .footer {
  margin: 0 auto;
  min-width: calc( 990px + 2.5rem);
  max-width: calc( 990px + 2.5rem);
}

.w-100 {
  width: 100% !important; 
}

.min-vh-100 {
  min-height: 100vh !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}


.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.PageDivider{
  min-height: 100%;
  width: .1rem;
  margin: 0 3.5rem;
  background-color: #000000;
}

.signup-header {
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 6rem;
}